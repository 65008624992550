import { TFunction } from 'i18next';

import config from '../../../config';
import { formatTimestamp } from '../../../lib/formatting';
import { GetProjectServiceDataResult } from '../../../redux/api/montageApi/types';
import { InstallationDataRowProps } from './types';
import {
  HemsSettings,
  HemsBatteryChargingMode,
  HemsBatteryChargingStrategy,
} from '../../../redux/api/iotCloud/types';

export const getCustomerDataRows = (
  data: GetProjectServiceDataResult['customer'] | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const { number, name, email, phone, formattedAddress } = data ?? {};

  return [
    {
      label: t('servicePage.customerData.customerNumber'),
      items: [{ value: number, feature: 'copy' }],
    },
    {
      label: t('servicePage.customerData.name'),
      items: [{ value: name }],
    },
    {
      label: t('servicePage.customerData.contact'),
      items: [{ value: email }, { value: phone }],
    },
    {
      label: t('servicePage.customerData.address'),
      items: [
        {
          value: formattedAddress,
          feature: 'link',
          link: config.servicePage.googleMapsLinkByText(formattedAddress),
        },
      ],
    },
  ];
};

export const getEkdIdDataRows = (
  data: GetProjectServiceDataResult['customer']['ekdIdStatus'] | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const {
    username,
    userId,
    emailVerified,
    lastLogin,
    createdAt,
    projectLinkedAt,
  } = data ?? {};

  const statusItems = [
    {
      value: emailVerified
        ? t('servicePage.customerAccountData.status.verified')
        : t('servicePage.customerAccountData.status.notVerified'),
    },
  ];
  if (!!lastLogin) {
    statusItems.push({
      value: t('servicePage.customerAccountData.status.lastLogin', {
        lastLogin: formatTimestamp(lastLogin, {
          seconds: false,
        }),
      }),
    });
  }
  if (!!createdAt) {
    statusItems.push({
      value: t('servicePage.customerAccountData.status.createdAt', {
        createdAt: formatTimestamp(createdAt, {
          seconds: false,
        }),
      }),
    });
  }
  if (!!projectLinkedAt) {
    statusItems.push({
      value: t('servicePage.customerAccountData.status.projectLinkedAt', {
        projectLinkedAt: formatTimestamp(createdAt, {
          seconds: false,
        }),
      }),
    });
  }

  return [
    {
      label: t('servicePage.customerAccountData.username'),
      items: [
        {
          value: username,
          feature: 'copy',
        },
      ],
    },
    {
      label: t('servicePage.customerAccountData.userId'),
      items: [
        {
          value: userId,
          link: config.servicePage.auth0UserProfileLink(userId),
          feature: 'link',
        },
      ],
    },
    {
      label: t('servicePage.customerAccountData.statusLabel'),
      items: statusItems,
    },
  ];
};

export const getProjectDataRows = (
  projectId: number | string,
  data: GetProjectServiceDataResult | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const {
    formattedLocationAddress,
    construction: {
      acStartDate,
      acEndDate,
      followUp1Reasons,
      followUp2Reasons,
      followUp3Reasons,
      projectReport,
      deliverySlips,
    },
    gateway: {
      lmSerialNumber,
      lmSerialNumberInverter,
      protocolSerialNumber,
      protocolGatewaySecret,
    },
  } = data ?? { construction: {}, gateway: {} };

  return [
    {
      label: t('servicePage.projectData.projectId'),
      items: [{ value: `${projectId}`, feature: 'copy' }],
    },
    {
      label: t('servicePage.projectData.address'),
      items: [
        {
          value: formattedLocationAddress,
          feature: 'link',
          link: config.servicePage.googleMapsLinkByText(
            formattedLocationAddress,
          ),
        },
      ],
    },
    {
      label: t('servicePage.projectData.acInstallationDate'),
      items: [
        {
          value: [
            formatTimestamp(acStartDate),
            formatTimestamp(acEndDate),
          ].join(' - '),
        },
      ],
    },
    {
      label: ` 1. ${t('servicePage.projectData.followUpAppoitment')}`,
      items: (followUp1Reasons ?? []).map(reason => ({ value: reason })),
    },
    {
      label: ` 2. ${t('servicePage.projectData.followUpAppoitment')}`,
      items: (followUp2Reasons ?? []).map(reason => ({ value: reason })),
    },
    {
      label: ` 3. ${t('servicePage.projectData.followUpAppoitment')}`,
      items: (followUp3Reasons ?? []).map(reason => ({ value: reason })),
    },
    {
      label: t('servicePage.projectData.projectReport'),
      items: [
        {
          value: projectReport
            ? t('servicePage.projectData.projectReportFilename')
            : '',
          feature: 'file',
          fileId: projectReport,
        },
      ],
    },
    {
      label: t('servicePage.projectData.deliverySlips'),
      items: (deliverySlips ?? []).map((slip, index) => ({
        value: `${t('servicePage.projectData.deliverySlipFilename')}_${index}`,
        feature: 'file',
        fileId: slip,
      })),
    },
    {
      label: t('servicePage.projectData.lmSerialNumberInverter'),
      items: [{ value: lmSerialNumberInverter, feature: 'copy' }],
    },
    {
      label: t('servicePage.projectData.lmSerialNumber'),
      items: [{ value: lmSerialNumber, feature: 'copy' }],
    },
    {
      label: t('servicePage.projectData.protocolSerialNumber'),
      items: [{ value: protocolSerialNumber, feature: 'copy' }],
    },
    {
      label: t('servicePage.projectData.protocolGatewaySecret'),
      items: [{ value: protocolGatewaySecret, feature: 'copy' }],
    },
  ];
};

export const getAmpereIqDataRows = (
  data: GetProjectServiceDataResult['installation'] | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const {
    id,
    createdAt,
    lat,
    lng,
    kgGatewaySerialNumber,
    kgInstallerCenterLink,
    kgSmartSetupLink,
    kgGatewayGuid,
    kgGatewaySecret,
    userAccount,
  } = data ?? {};
  return [
    {
      label: t('servicePage.installationData.createdAt'),
      items: [{ value: formatTimestamp(createdAt) }],
    },
    {
      label: t('servicePage.installationData.kgGatewaySerialNumber'),
      items: [{ value: kgGatewaySerialNumber, feature: 'copy' }],
    },
    {
      label: t('servicePage.installationData.kgUsername'),
      items: [
        {
          value: userAccount?.kgUsername,
          feature: 'copy',
        },
      ],
    },
    {
      label: t('servicePage.installationData.kgUser'),
      items: [
        {
          value: userAccount?.kgUserId,
          link: kgInstallerCenterLink,
          feature: 'link',
        },
      ],
    },
    {
      label: t('servicePage.installationData.kgGatewaySecret'),
      items: [{ value: kgGatewaySecret, feature: 'copy' }],
    },
    {
      label: t('servicePage.installationData.kgSmartSetup'),
      items: [
        {
          value: kgGatewayGuid,
          link: kgSmartSetupLink,
          feature: 'link',
          valueToCopy: kgGatewaySecret,
        },
      ],
    },
    {
      label: t('servicePage.installationData.location'),
      items: [
        {
          value:
            lat && lng ? `${lat}, ${lng}` : id ? t('common.unknown') : null,
          link: config.servicePage.googleMapsLinkByCoordinates(lat, lng),
          feature: lat && lng ? 'link' : undefined,
        },
      ],
    },
    {
      label: t('servicePage.installationData.userAccountEkdId'),
      items: [
        {
          value: userAccount?.auth0UserId,
          link: config.servicePage.auth0UserProfileLink(
            userAccount?.auth0UserId,
          ),
          feature: 'link',
        },
      ],
    },
  ];
};

export const getPriceBasedChargingRows = (
  data: HemsSettings | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const {
    batteryChargingEnabled,
    batteryChargingThreshold,
    batteryChargingStrategy,
    batteryChargingMode,
    batteryChargingPriceLimit,
  } = data ?? {};

  return [
    {
      label: t('common.enabled'),
      items: [
        {
          value: batteryChargingEnabled
            ? t('common.yes')
            : batteryChargingEnabled === false
            ? t('common.no')
            : '',
        },
      ],
    },
    {
      label: t('servicePortal.installationDetailsPage.chargingTo'),
      items: [
        {
          value: batteryChargingThreshold ? `${batteryChargingThreshold}%` : '',
        },
      ],
    },
    {
      label: t('servicePortal.installationDetailsPage.strategy'),
      items: [
        {
          value: batteryChargingStrategy
            ? batteryChargingStrategy === HemsBatteryChargingStrategy.Dynamic
              ? t('servicePortal.installationDetailsPage.dynamic')
              : t('servicePortal.installationDetailsPage.manual')
            : '',
        },
      ],
    },
    {
      label: t('servicePortal.installationDetailsPage.chargingMode'),
      items: [
        {
          value: batteryChargingMode
            ? batteryChargingMode === HemsBatteryChargingMode.Fast
              ? t('servicePortal.installationDetailsPage.fast')
              : t('servicePortal.installationDetailsPage.gentle')
            : '',
        },
      ],
    },
    {
      label: t('servicePortal.installationDetailsPage.priceLimit'),
      items: [
        {
          value: batteryChargingPriceLimit
            ? `${batteryChargingPriceLimit} cent`
            : '',
        },
      ],
    },
  ];
};

export const getSolarBasedCarChargingRows = (
  data: HemsSettings | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const { evChargingEnabled, evChargingSnoozeUntilTimestamp } = data ?? {};

  return [
    {
      label: t('common.enabled'),
      items: [
        {
          value:
            evChargingEnabled === true
              ? t('common.yes')
              : evChargingEnabled === false
              ? t('common.no')
              : '',
        },
      ],
    },
    {
      label: t('servicePortal.installationDetailsPage.snoozeUntil'),
      items: [
        {
          value: evChargingSnoozeUntilTimestamp
            ? formatTimestamp(evChargingSnoozeUntilTimestamp)
            : '-',
        },
      ],
    },
  ];
};

export const getEmergencyPowerReserveRows = (
  data: HemsSettings | undefined,
  t: TFunction<'translation'>,
): InstallationDataRowProps[] => {
  const { emergencyPowerEnabled, emergencyPowerLimit } = data ?? {};

  return [
    {
      label: t('common.enabled'),
      items: [
        {
          value:
            emergencyPowerEnabled === true
              ? t('common.yes')
              : emergencyPowerEnabled === false
              ? t('common.no')
              : '',
        },
      ],
    },
    {
      label: t('servicePortal.installationDetailsPage.limit'),
      items: [
        {
          value: emergencyPowerLimit ? `${emergencyPowerLimit}%` : '',
        },
      ],
    },
  ];
};
