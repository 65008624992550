import {
  Badge,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetBatteryChargeMigrationStatusQuery } from '../../../redux/api/iotCloud';
import LoadingCard from '../InstallationDetailsPage/LoadingCard';
import { formatBytes, formatTimestamp } from '../../../lib/formatting';
import {
  BatteryChargeMigrationJobData,
  HyperTableChunk,
  SimpleJobState,
} from '../../../redux/api/iotCloud/typeCopy';
import QueueIcon from '@mui/icons-material/Queue';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { orderBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

const ChunksTable = ({ data }: { data: HyperTableChunk[] }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Range Start
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Range End
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Total Bytes
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Chunk Name
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((chunk, index) => (
          <TableRow key={chunk.chunk_name}>
            <TableCell>
              <Typography>{chunk.range_start}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{chunk.range_end}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatBytes(chunk.total_bytes)}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{chunk.chunk_name}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const JobStatusIcon = ({
  state,
  result,
}: {
  state?: SimpleJobState;
  result?: string;
}) => {
  if (state === SimpleJobState.queued) {
    return <QueueIcon color="info" />;
  }
  if (state === SimpleJobState.processing) {
    return <CircularProgress size={24} />;
  }
  if (state === SimpleJobState.completed) {
    if (result === 'paused') {
      return <WarningIcon color="info" />;
    }
    return <DoneIcon color="success" />;
  }
  if (state === SimpleJobState.failed) {
    if (result === 'paused') {
      return <WarningIcon color="warning" />;
    }
    return <ErrorIcon color="error" />;
  }
  return <Typography>{state}</Typography>;
};

const BatteryChargeMigrationJobTable = ({
  data,
}: {
  data: {
    id?: string;
    name: string;
    state: SimpleJobState;
    data: BatteryChargeMigrationJobData;
    failedReason?: string;
  }[];
}) => {
  const sorted = orderBy(
    data,
    ['data.startedAt', 'data.queuedAt'],
    ['desc', 'desc'],
  );

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Status
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Chunk
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Progress
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Result
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Queued At
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Started At
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="secondary.main" variant="h6">
              Finished At
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sorted.map((job, index) => (
          <TableRow key={job.id}>
            <TableCell>
              <JobStatusIcon state={job.state} result={job.data?.result} />
            </TableCell>
            <TableCell>
              <Typography>{job.name}</Typography>
            </TableCell>
            <TableCell>
              {job.name === 'migrateNextChunk' && (
                <Typography>{job.data?.chunk?.chunk_name}</Typography>
              )}
              {job.name === 'deleteMigratedChunks' && (
                <Typography>
                  {job.data?.droppedChunks?.length
                    ? `Dropped: ${job.data?.droppedChunks
                        ?.map(dc => dc.drop_chunks)
                        .join(', ')}`
                    : 'None'}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {job.name === 'migrateNextChunk' && (
                <Typography>
                  {job.data?.processedInstallationCount &&
                  job.data?.startingInstallationCount
                    ? `${Math.round(
                        ((job.data?.processedInstallationCount ?? 0) /
                          (job.data?.startingInstallationCount ?? 0)) *
                          100,
                      )}% (${job.data?.processedInstallationCount} / ${
                        job.data?.startingInstallationCount
                      } Installations)`
                    : '-'}
                </Typography>
              )}
              {job.name === 'deleteMigratedChunks' && (
                <Typography>
                  {`${job.data?.droppedChunks
                    ?.map(
                      dc =>
                        `data_from_ts: ${formatTimestamp(
                          dc.data_from_ts,
                        )} offline_from_ts: ${formatTimestamp(
                          dc.offline_from_ts,
                        )}`,
                    )
                    .join(', ')}`}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              <Typography>{job.data?.result ?? job.failedReason}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatTimestamp(job.data?.queuedAt)}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatTimestamp(job.data?.startedAt)}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatTimestamp(job.data?.finishedAt)}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const BatteryChargeMigrationStatusCard = () => {
  const navigate = useNavigate();
  const { data, isFetching, isLoading } =
    useGetBatteryChargeMigrationStatusQuery(undefined, {
      pollingInterval: 60000, // 1min
      skipPollingIfUnfocused: true,
    });

  const chipValues = [
    {
      key: 'paused',
      label: data?.isPaused ? 'Paused' : 'Running',
    },
    {
      key: 'battery_charge_tableSize',
      label: `battery_charge table: ${
        data?.battery_charge_tableSize
          ? formatBytes(data?.battery_charge_tableSize)
          : '-'
      }`,
      value:
        data?.battery_charge_tableSize && data?.initial_battery_charge_tableSize
          ? `${Math.round(
              (1 -
                data?.battery_charge_tableSize /
                  data?.initial_battery_charge_tableSize) *
                100,
            )}%`
          : undefined,
      linkTo: '/servicePortal/admin/hyperTable/battery_charge',
    },
    {
      key: 'battery_charge_v2_tableSize',
      label: `battery_charge_v2 table: ${
        data?.battery_charge_v2_tableSize
          ? formatBytes(data?.battery_charge_v2_tableSize)
          : '-'
      }`,
      value:
        data?.battery_charge_v2_tableSize &&
        data?.initial_battery_charge_v2_tableSize
          ? `${Math.round(
              (1 -
                data?.initial_battery_charge_v2_tableSize /
                  data?.battery_charge_v2_tableSize) *
                100,
            )}%`
          : undefined,
      linkTo: '/servicePortal/admin/hyperTable/battery_charge_v2',
    },
    {
      key: 'installation_batch_size',
      label: `installation_batch_size: ${data?.setting?.installation_batch_size}`,
    },
    {
      key: 'migrate_until',
      label: `migrate_until: ${
        data?.setting?.migrate_until
          ? formatTimestamp(data?.setting?.migrate_until)
          : '-'
      }`,
      value: data?.migratedToTimestamp
        ? formatTimestamp(data?.migratedToTimestamp)
        : undefined,
    },
    {
      key: 'delete_migrated_chunks_before',
      label: `delete migrated chunks before: ${
        data?.setting?.delete_migrated_chunks_before
          ? formatTimestamp(data?.setting?.delete_migrated_chunks_before)
          : '-'
      }`,
    },
  ];

  return (
    <LoadingCard
      isLoading={isLoading}
      isLoadingWithContent={isFetching}
      title="Battery Charge Migration Status"
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {chipValues.map(cv => (
          <Badge
            key={cv.key}
            color="secondary"
            badgeContent={cv.value}
            max={99999}
          >
            <Chip
              label={cv.label}
              onClick={cv.linkTo ? () => navigate(cv.linkTo) : undefined}
              sx={{
                '& .MuiChip-label': {
                  whiteSpace: 'normal',
                  fontWeight: 'bold',
                },
              }}
            />
          </Badge>
        ))}
      </Stack>
      <Typography variant="h6">{`${data?.chunksToMigrate?.length} Chunks to migrate:`}</Typography>
      <ChunksTable data={data?.chunksToMigrate ?? []} />
      <Typography variant="h6">{`${data?.jobs?.length} Jobs:`}</Typography>
      <BatteryChargeMigrationJobTable data={data?.jobs ?? []} />
    </LoadingCard>
  );
};

export default BatteryChargeMigrationStatusCard;
