import {
  Box,
  Button,
  debounce,
  LinearProgress,
  Skeleton,
  Stack,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';

import { useGetIotCloudSearchQuery } from '../../../redux/api/iotCloud';
import ServicePortalLayout from '../ServicePortalLayout';
import { skipToken } from '@reduxjs/toolkit/query';
import SearchResultTable from './SearchResultTable';
import SearchInput from './SearchInput';
import { useTranslation } from 'react-i18next';
import { GlobalParamEnum, useGlobalParam } from '../../../lib/hooks';
import { SubmittedParamExposeType } from '../../../redux/servicePortal';

export const DEFAULT_RECORDS_PER_PAGE = 25;

const SearchPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.search',
  });

  const [phrase, submittedPhrase, setPhrase, submitPhrase] = useGlobalParam(
    GlobalParamEnum.Search,
    SubmittedParamExposeType.URL,
    {
      replaceUrlHistory: true,
    },
  );
  const [loadMoreParam, setLoadMoreParam] = useState(0);

  const queryArgs = useMemo(() => {
    const loadMore = Number(loadMoreParam) || 0;
    return {
      phrase: `${submittedPhrase}`,
      loadMore,
      lazyResults: true,
      skipAfterSpecific: false,
    };
  }, [submittedPhrase, loadMoreParam]);

  const { data, isFetching, isSuccess, refetch } = useGetIotCloudSearchQuery(
    queryArgs ?? skipToken,
  );

  const handleSubmit = useMemo(
    () =>
      debounce((newPhrase: string) => {
        setLoadMoreParam(0);
        submitPhrase(newPhrase);
      }, 1000),
    [submitPhrase, setLoadMoreParam],
  );

  const handleSearchValueChange = (newPhrase: string) => {
    setPhrase(newPhrase);
    handleSubmit(newPhrase);
  };

  const handleLoadMore = useCallback(() => {
    setLoadMoreParam(queryArgs.loadMore + 1);
  }, [queryArgs, setLoadMoreParam]);

  const handleReload = () => {
    refetch();
  };

  useEffect(() => {
    if (data?.canLoadMore && queryArgs.loadMore < 3 && !isFetching) {
      handleLoadMore();
    }
  }, [data, queryArgs, isFetching, handleLoadMore]);

  // only show results if these are for the current search phrase
  const showResults = isSuccess && (!isFetching || loadMoreParam >= 1);

  return (
    <ServicePortalLayout>
      <Stack gap={1}>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flex: 1,
            gap: 2,
          }}
        >
          <SearchInput
            searchValue={`${phrase ?? ''}`}
            onSearchValueChange={handleSearchValueChange}
          />
          {Boolean(data?.canLoadMore) && (
            <Button disabled={isFetching} onClick={handleReload}>
              <ReplayIcon />
            </Button>
          )}
        </Stack>
        <Box
          sx={{
            height: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {isFetching && <LinearProgress />}
        </Box>
        {showResults && (
          <SearchResultTable
            results={data?.results ?? []}
            filters={queryArgs}
          />
        )}
        {!showResults && <Skeleton height={300} variant="rectangular" />}
        {Boolean(data?.canLoadMore) && (
          <Button
            disabled={isFetching || queryArgs.loadMore >= 10}
            onClick={handleLoadMore}
          >
            {queryArgs.loadMore >= 10
              ? t('loadMoreButtonMaximumLabel')
              : t('loadMoreButtonLabel')}
          </Button>
        )}
      </Stack>
    </ServicePortalLayout>
  );
};

export default SearchPage;
