import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import {
  selectSharedInputValue,
  selectSubmittedInput,
  selectSubmittedInputValue,
} from '../redux/selectors';
import {
  setSharedInputValue,
  setSubmittedInputValue,
  SubmittedParamExposeType,
} from '../redux/servicePortal';
import { RootState } from '../redux';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const usePreviousPersistent = <T>(value: T) => {
  // initialise the ref with previous and current values
  const ref = useRef<{ value: T; prev: T | null }>({
    value: value,
    prev: null,
  });

  const current = ref.current.value;

  // if the value passed into hook doesn't match what we store as "current"
  // move the "current" to the "previous"
  // and store the passed value as "current"
  if (value !== current) {
    ref.current = {
      value: value,
      prev: current,
    };
  }

  // return the previous value only
  return ref.current.prev;
};

export enum GlobalParamEnum {
  Search = 'q',
  LoadMore = 'qc',
  GlobalSearch = 'gq',
}

/**
 * Caeful: react-router-dom has timing issues if multiple searchParams are updated independently shortly after another
 * if you need to update multiple searchParams at once, consider using useGlobalParam instead
 * @param key name of the searchParam
 * @returns
 */
export const useSearchParam = (key: GlobalParamEnum) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSearchParamsChange = useCallback(
    (value?: string | number | boolean | null) => {
      let params: URLSearchParams = searchParams;
      setSearchParams(previous => {
        const newParams = new URLSearchParams(previous);
        newParams.set(key, isNil(value) ? '' : value.toString());
        params = newParams;
        return newParams;
      });
      return params;
    },
    [key, setSearchParams, searchParams],
  );
  return [searchParams.get(key) ?? '', handleSearchParamsChange] as const;
};

export const useGlobalParam = (
  key: GlobalParamEnum,
  expose: SubmittedParamExposeType = SubmittedParamExposeType.NONE,
  { replaceUrlHistory = false } = {},
) => {
  const dispatch = useDispatch();
  const storedValue = useSelector<RootState, string | number | boolean | null>(
    state => selectSharedInputValue(state, key),
  );
  const setStored = useCallback(
    (value: string | number | boolean) =>
      dispatch(setSharedInputValue({ key, value })),
    [key, dispatch],
  );

  const submittedValue = useSelector<
    RootState,
    string | number | boolean | null
  >(state => selectSubmittedInputValue(state, key));
  const setSubmitted = useCallback(
    (value: string | number | boolean, expose: SubmittedParamExposeType) =>
      dispatch(setSubmittedInputValue({ key, value, expose })),
    [key, dispatch],
  );

  // update the searchParams with the new submitted values
  const [searchParams, setSearchParams] = useSearchParams();

  const submitted = useSelector(selectSubmittedInput);
  // submit the value to the store
  const submit = useCallback(
    (newValue: string | number | boolean) => {
      setStored(newValue);
      setSubmitted(newValue, expose);
      // return all submitted values, including the update
      const newSubmitted = {
        ...submitted,
        [key]: {
          value: newValue,
          expose,
        },
      };
      // return the search params, including the updated value
      const newSearchParams = new URLSearchParams(
        Object.entries(newSubmitted)
          .filter(
            ([key, { expose }]) => expose === SubmittedParamExposeType.URL,
          )
          .reduce((acc, [key, { value }]) => ({ ...acc, [key]: value }), {}),
      );
      if (expose === SubmittedParamExposeType.URL) {
        setSearchParams(
          prev => {
            prev.set(key, `${newValue}`);
            return prev;
          },
          {
            preventScrollReset: true,
            replace: replaceUrlHistory,
          },
        );
      }
      return [newSearchParams, newSubmitted] as const;
    },
    [
      key,
      expose,
      submitted,
      setStored,
      setSubmitted,
      setSearchParams,
      replaceUrlHistory,
    ],
  );

  return [
    storedValue ?? submittedValue ?? searchParams.get(key) ?? '',
    submittedValue ?? searchParams.get(key) ?? '',
    setStored,
    submit,
  ] as const;
};
