import { Tooltip, useTheme } from '@mui/material';
import get from 'lodash/get';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

interface IconWithTooltipProps {
  tooltipColor?: string;
  titleComponent: JSX.Element;
  onOpen?: (event: React.SyntheticEvent) => void;
  onClose?: (event: React.SyntheticEvent | Event) => void;
}

const IconWithTooltip = ({
  tooltipColor = 'common.black',
  titleComponent,
  onOpen,
  onClose,
}: IconWithTooltipProps) => {
  const theme = useTheme();
  const iconFill = get(theme.palette, tooltipColor, 'common.black');

  return (
    <Tooltip
      title={titleComponent}
      placement="bottom"
      onOpen={onOpen}
      onClose={onClose}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: tooltipColor,
          },
        },
        arrow: {
          sx: {
            color: tooltipColor,
          },
        },
      }}
      arrow
    >
      <InfoIcon fill={iconFill} />
    </Tooltip>
  );
};

export default IconWithTooltip;
