import { Box, TextField } from '@mui/material';
import { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

const SearchInput = ({
  searchValue,
  onSearchValueChange,
}: {
  searchValue: string;
  onSearchValueChange: (newValue: string) => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.search',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onSearchValueChange(value);
  };

  return (
    <Box sx={{ flex: 1, display: 'flex', gap: 1.5 }}>
      <TextField
        size="medium"
        label={t('phraseInputLabel')}
        value={searchValue}
        onChange={handleChange}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

export default SearchInput;
