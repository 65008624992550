import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckmarkIcon } from '../../../../../assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ex.svg';
import { ReactComponent as SendIcon } from '../../../../../assets/icons/send.svg';
import { useSnackbar } from '../../../../../context/SnackbarContext';
import { usePostEkdIdReminderEmailMutation } from '../../../../../redux/api/montageApi';
import CustomDialogTitle from '../../../../common/dialog/CustomDialogTitle';
import DialogFooter from '../../../../common/dialog/DialogFooter';

interface EkdIdReminderEmailDialogProps {
  isOpen: boolean;
  onClose: () => void;
  emailAddress: string;
  projectId: string | number;
  onSuccess: () => void;
}

const EkdIdReminderEmailDialog = ({
  isOpen,
  onClose,
  emailAddress,
  projectId,
  onSuccess,
}: EkdIdReminderEmailDialogProps) => {
  const { t } = useTranslation('translation');
  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [sendEmail] = usePostEkdIdReminderEmailMutation();

  const onConfirm = () => {
    setIsLoading(true);
    sendEmail(projectId)
      .unwrap()
      .then(() => {
        onSuccess();
        showSnackbar(
          t('servicePage.ekdIdReminderDialog.successMessage'),
          'success',
        );
      })
      .catch(error => {
        if (error?.status === 429) {
          showSnackbar(
            t('servicePage.ekdIdReminderDialog.tooManyRequestsMessage'),
            'error',
          );
        } else {
          showSnackbar(
            t('servicePage.ekdIdReminderDialog.generalErrorMessage'),
            'error',
          );
        }
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        onClose();
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <CustomDialogTitle icon={<SendIcon fill="black" />} onClose={onClose}>
        {t('servicePage.ekdIdReminderDialog.title')}
      </CustomDialogTitle>
      <DialogContent
        sx={{
          minWidth: 600,
          minHeight: 170,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isLoading ? (
          <CircularProgress size={50} sx={{ m: 'auto' }} />
        ) : (
          <>
            <Typography>
              {t('servicePage.ekdIdReminderDialog.message')}
            </Typography>
            <Typography sx={{ fontWeight: 700, mt: 3 }}>
              {t('servicePage.ekdIdReminderDialog.emailLabel')}
            </Typography>
            <Typography color="secondary.main" sx={{ fontWeight: 700 }}>
              {emailAddress}
            </Typography>
            <DialogFooter
              buttonsData={[
                {
                  colorVariant: 'secondary',
                  icon: <CloseIcon />,
                  label: t('common.cancel'),
                  onClick: onClose,
                },
                {
                  colorVariant: 'tertiary',
                  icon: <CheckmarkIcon />,
                  label: t('common.confirm'),
                  onClick: onConfirm,
                },
              ]}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EkdIdReminderEmailDialog;
