import { createApi } from '@reduxjs/toolkit/query/react';
import { montageApiBaseQueryWithReauth, profileQueryFn } from '../baseQueries';
import {
  GetProjectServiceDataResult,
  PostConnectGatewayArgs,
  PostConnectGatewayResult,
} from './types';
import { GetInstallationStatusResult } from '../iotCloud/types';

const montageApi = createApi({
  baseQuery: montageApiBaseQueryWithReauth,
  reducerPath: 'montageApi',
  tagTypes: ['installationStatus'],
  endpoints: b => ({
    getProfile: b.query<any, void>({
      queryFn: profileQueryFn,
    }),
    getProjectServiceData: b.query<
      GetProjectServiceDataResult,
      string | number
    >({
      query: projectId => `api/v1/frontend/project/${projectId}/service`,
    }),
    getInstallationStatus: b.query<
      GetInstallationStatusResult,
      string | number
    >({
      providesTags: ['installationStatus'],
      query: installationId =>
        `api/v1/frontend/iot_cloud/installation/${installationId}/status`,
    }),
    postEkdIdReminderEmail: b.mutation<void, string | number>({
      query: projectId => ({
        method: 'POST',
        url: `api/v1/frontend/project/${projectId}/customer_mail`,
      }),
    }),
    postConnectGateway: b.mutation<
      PostConnectGatewayResult,
      PostConnectGatewayArgs
    >({
      invalidatesTags: ['installationStatus'],
      query: ({ projectId, serialNumber, gatewaySecret }) => ({
        method: 'POST',
        url: `api/v1/project/${projectId}/connect_gateway/${serialNumber}`,
        body: {
          gatewaySecret,
        },
      }),
    }),
    deleteInstallation: b.mutation<void, string>({
      invalidatesTags: ['installationStatus'],
      query: installationId => ({
        method: 'DELETE',
        url: `api/v1/frontend/iot_cloud/installation/${installationId}`,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetProjectServiceDataQuery,
  useGetInstallationStatusQuery,
  usePostEkdIdReminderEmailMutation,
  usePostConnectGatewayMutation,
  useDeleteInstallationMutation,
} = montageApi;

export default montageApi;
