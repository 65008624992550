import { Grid } from '@mui/material';
import ServicePortalLayout from '../ServicePortalLayout';
import InstallationDeletionCard from './InstallationDeletionCard';
import KpiDashboardCard from './KpiDashboardCard';
import { useGetMeQuery } from '../../../redux/api/iotCloud';
import { hasPermission, IoTCloudPermissionEnum } from '../../../lib/helpers';
import BatteryChargeMigrationStatusCard from './BatteryChargeMigrationStatusCard';

const MainPage = () => {
  const { data: getMeData } = useGetMeQuery();
  return (
    <ServicePortalLayout>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} xl={6}>
          <KpiDashboardCard />
        </Grid>
        <Grid item sm={12} xl={6}>
          <InstallationDeletionCard />
        </Grid>
        {hasPermission(getMeData, IoTCloudPermissionEnum.Admin) && (
          <Grid item sm={12}>
            <BatteryChargeMigrationStatusCard />
          </Grid>
        )}
      </Grid>
    </ServicePortalLayout>
  );
};

export default MainPage;
