import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { useGetProfileQuery } from '../../redux/api/montageApi';
import { selectShouldShowLmLogin } from '../../redux/selectors';
import InstallationDetailsPage from './InstallationDetailsPage';
import InstallationPage from './InstallationPage';
import LMLoginPage from '../LMLoginPage';
import MainPage from './MainPage';
import HyperTablePage from './HyperTablePage';
import HyperTableDetailsPage from './HyperTablePage/HyperTableDetailsPage';
import ProjectDetailsPage from './ProjectDetailsPage';
import SearchPage from './SearchPage';

export const ServicePortal = () => {
  const shouldShowLmLogin = useSelector(selectShouldShowLmLogin);
  // Query used just to verify that user is logged in
  const { refetch } = useGetProfileQuery();

  if (shouldShowLmLogin) {
    return <LMLoginPage withIframe onLoad={refetch} />;
  }
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path={`installation`} element={<InstallationPage />} />,
      <Route
        path={`installation/:installationId`}
        element={<InstallationDetailsPage />}
      />
      ,
      <Route path={`admin/hyperTable`} element={<HyperTablePage />} />,
      <Route
        path={`admin/hyperTable/:hyperTableName`}
        element={<HyperTableDetailsPage />}
      />
      <Route path={`search`} element={<SearchPage />} />,
      <Route path={`project/:projectId`} element={<ProjectDetailsPage />} />,
    </Routes>
  );
};

export default ServicePortal;
