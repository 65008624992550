import { useParams } from 'react-router-dom';
import ServicePortalLayout from './ServicePortalLayout';
import ProjectDetails from './ProjectDetails';

const ProjectDetailsPage = () => {
  const { projectId } = useParams();

  return (
    <ServicePortalLayout>
      <ProjectDetails projectId={projectId} />
    </ServicePortalLayout>
  );
};

export default ProjectDetailsPage;
