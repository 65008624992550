import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import subDays from 'date-fns/subDays';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PriceSignalFilters from './PriceSignalFilters';
import PriceSignalTable from './PriceSignalTable';
import {
  useGetBatteryChargeHistoryQuery,
  useGetBatteryChargeLastQuery,
} from '../../../../redux/api/iotCloud';
import { differenceInSeconds, endOfDay, startOfDay } from 'date-fns';
import { flatMap, orderBy } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query';
import BatteryChargeTable from './BatteryChargeTable';
import { GetBatteryChargeHistoryItem } from '../../../../redux/api/iotCloud/types';

const LIMIT = 10;
const MAX_DAYS = 28;

type PriceSignalSectionProps = {
  installationId?: string | number;
};

const PriceSignalSection = ({ installationId }: PriceSignalSectionProps) => {
  const { t } = useTranslation('translation');
  const { t: t_psc } = useTranslation('translation', {
    keyPrefix: 'servicePortal.priceSignalCard',
  });

  const {
    data: lastBatteryChargeCommands,
    isSuccess: isSuccessLastBatteryChargeCommands,
    isFetching: isFetchingLastBatteryChargeCommands,
    refetch: refetchLastBatteryChargeCommands,
  } = useGetBatteryChargeLastQuery(installationId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (lastBatteryChargeCommands && lastBatteryChargeCommands[0]?.timestamp) {
      const secondsSinceLastBatteryCharge = differenceInSeconds(
        new Date(),
        new Date(lastBatteryChargeCommands[0].timestamp),
      );
      // battery charge requests are every 60 seconds
      let timeoutSeconds = 60;
      if (secondsSinceLastBatteryCharge < 65) {
        // refetch 5s after there should have been a new one
        timeoutSeconds = 65 - secondsSinceLastBatteryCharge;
      } else if (secondsSinceLastBatteryCharge > 360) {
        // refetch after 10 minutes in case the last battery charge is older than 1h
        timeoutSeconds = 600;
      }
      timeout = setInterval(() => {
        refetchLastBatteryChargeCommands();
      }, timeoutSeconds * 1000);
    }
    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [lastBatteryChargeCommands, refetchLastBatteryChargeCommands]);

  const [pages, setPages] = useState(
    {} as Record<number, GetBatteryChargeHistoryItem[]>,
  );
  const [offset, setOffset] = useState(0);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());

  const resetPagination = () => {
    setPages({});
    setOffset(0);
  };

  const { data, isSuccess, isFetching, isError, refetch } =
    useGetBatteryChargeHistoryQuery(
      {
        installationId: installationId ?? '',
        limit: LIMIT,
        offset,
        from: startOfDay(fromDate).toISOString(),
        to: endOfDay(toDate).toISOString(),
        sort: ['timestamp', 'batteryId'],
        order: ['desc', 'asc'],
      },
      { skip: !installationId },
    );

  useEffect(() => {
    if (data) {
      setPages(prev => ({
        ...prev,
        [data.meta?.offset ?? offset]: data.data,
      }));
    }
  }, [offset, data]);

  const handleDatesChange = (newFromDate: Date, newToDate: Date) => {
    const datesDiffInDays = differenceInDays(newToDate, newFromDate);
    if (datesDiffInDays > MAX_DAYS) {
      setFromDate(newFromDate);
      setToDate(addDays(newFromDate, MAX_DAYS));
    } else if (datesDiffInDays <= 0) {
      setFromDate(newFromDate);
      setToDate(addDays(newFromDate, 1));
    } else {
      setFromDate(newFromDate);
      setToDate(newToDate);
    }
    resetPagination();
  };
  const handleFromDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    handleDatesChange(date, toDate);
  };
  const handleToDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    handleDatesChange(fromDate, date);
  };

  const handleNextPage = () => {
    setOffset(prev => prev + (data?.meta?.limit ?? LIMIT));
  };

  const handleRefresh = () => {
    resetPagination();
    refetch();
  };

  const hasNextPage = data?.meta?.count && data?.meta?.count >= LIMIT;
  // data array from ordered pages
  const allData = useMemo(
    () =>
      flatMap(
        orderBy(Object.entries(pages), [([key]) => parseInt(key)], ['asc']).map(
          ([, value]) => value,
        ),
      ),
    [pages],
  );

  return (
    <Stack gap={1}>
      <Typography variant="h4" color="secondary.main">
        {t_psc('batteryChargeLastTitle')}
      </Typography>
      <Box
        sx={{
          height: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetchingLastBatteryChargeCommands && <LinearProgress />}
      </Box>
      {!isSuccessLastBatteryChargeCommands && (
        <Skeleton height={100} variant="rectangular" />
      )}
      {isSuccessLastBatteryChargeCommands && (
        <BatteryChargeTable data={lastBatteryChargeCommands} />
      )}
      <Box sx={{ height: 10 }} />
      <Typography variant="h4" color="secondary.main">
        {t_psc('batteryChargeHistoryTitle')}
      </Typography>
      <PriceSignalFilters
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={handleFromDateChange}
        onToDateChange={handleToDateChange}
        maxDays={MAX_DAYS}
        onRefresh={handleRefresh}
      />
      {isError && (
        <Alert
          severity="error"
          sx={{ alignItems: 'center' }}
          action={
            <Button variant="outlined" color="error" onClick={refetch}>
              Retry
            </Button>
          }
        >
          {t('common.unknownErrorTryLater')}
        </Alert>
      )}
      <Box
        sx={{
          my: 1,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      {!isSuccess && (
        <Skeleton height={isError ? 100 : 200} variant="rectangular" />
      )}
      {isSuccess && <PriceSignalTable data={allData} />}
      {Boolean(hasNextPage) && (
        <Button disabled={isFetching} onClick={handleNextPage}>
          {t('servicePage.historyTable.loadMoreButtonLabel')}
        </Button>
      )}
    </Stack>
  );
};

export default PriceSignalSection;
