import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HeaderCell = ({
  children,
  colSpan,
}: {
  children: React.ReactNode;
  colSpan?: number;
}) => {
  return (
    <TableCell colSpan={colSpan}>
      <Typography sx={{ fontWeight: 700 }}>{children}</Typography>
    </TableCell>
  );
};

const HemsSettingsAuditTableHeaders = () => {
  const { t } = useTranslation('translation');

  return (
    <TableHead>
      <TableRow>
        <HeaderCell colSpan={3}>{t('common.change')}</HeaderCell>
        <HeaderCell colSpan={5}>
          {t('servicePortal.installationDetailsPage.priceBasedCharging')}
        </HeaderCell>
        <HeaderCell colSpan={2}>
          {t('servicePortal.installationDetailsPage.solarBasedCarCharging')}
        </HeaderCell>
        <HeaderCell colSpan={2}>
          {t('servicePortal.installationDetailsPage.emergencyPowerReserve')}
        </HeaderCell>
      </TableRow>
      <TableRow>
        <HeaderCell>{t('common.type')}</HeaderCell>
        <HeaderCell>{t('common.at')}</HeaderCell>
        <HeaderCell>{t('common.by')}</HeaderCell>
        <HeaderCell>{t('common.enabled')}</HeaderCell>
        <HeaderCell>
          {t('servicePortal.installationDetailsPage.chargingTo')}
        </HeaderCell>
        <HeaderCell>
          {t('servicePortal.installationDetailsPage.strategy')}
        </HeaderCell>
        <HeaderCell>
          {t('servicePortal.installationDetailsPage.chargingMode')}
        </HeaderCell>
        <HeaderCell>
          {t('servicePortal.installationDetailsPage.priceLimit')}
        </HeaderCell>
        <HeaderCell>{t('common.enabled')}</HeaderCell>
        <HeaderCell>
          {t('servicePortal.installationDetailsPage.snoozeUntil')}
        </HeaderCell>
        <HeaderCell>{t('common.enabled')}</HeaderCell>
        <HeaderCell>
          {t('servicePortal.installationDetailsPage.limit')}
        </HeaderCell>
      </TableRow>
    </TableHead>
  );
};

export default HemsSettingsAuditTableHeaders;
