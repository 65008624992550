import {
  Box,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { formatDistance } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDateLocale } from '../../../context/TranslationContext/useDateLocale';
import { formatTimestamp } from '../../../lib/formatting';
import IconWithTooltip from '../../common/IconWithTooltip';
import {
  GetInstallationStatusResult,
  InstallationConnectionStatus,
  InstallationOnlineStatus,
} from '../../../redux/api/iotCloud/types';
import { useGetInstallationStatusQuery } from '../../../redux/api/iotCloud';

export const transformInstallationStatusData = (
  data?: GetInstallationStatusResult,
) => {
  const { onlineStatus, connectionStatus } = data ?? {};

  if (
    onlineStatus === InstallationOnlineStatus.Unknown &&
    connectionStatus === InstallationConnectionStatus.NotConnected
  ) {
    return {
      color: 'error.main',
      statusLabelTranslationKey:
        'servicePage.statusBar.notConnectedUnkown.label',
      tooltipTranslationKey: 'servicePage.statusBar.notConnectedUnkown.tooltip',
      showLastReportDate: false,
    };
  }
  if (
    onlineStatus === InstallationOnlineStatus.Offline &&
    connectionStatus === InstallationConnectionStatus.ProjectConnected
  ) {
    return {
      color: 'primary.main',
      statusLabelTranslationKey:
        'servicePage.statusBar.projectConnectedOffline.label',
      tooltipTranslationKey:
        'servicePage.statusBar.projectConnectedOffline.tooltip',
      showLastReportDate: true,
    };
  }
  if (
    onlineStatus === InstallationOnlineStatus.Offline &&
    connectionStatus === InstallationConnectionStatus.Connected
  ) {
    return {
      color: 'primary.main',
      statusLabelTranslationKey: 'servicePage.statusBar.connectedOffline.label',
      tooltipTranslationKey: 'servicePage.statusBar.connectedOffline.tooltip',
      showLastReportDate: true,
    };
  }
  if (
    onlineStatus === InstallationOnlineStatus.Online &&
    connectionStatus === InstallationConnectionStatus.ProjectConnected
  ) {
    return {
      color: 'info.main',
      statusLabelTranslationKey:
        'servicePage.statusBar.projectConnectedOnline.label',
      tooltipTranslationKey:
        'servicePage.statusBar.projectConnectedOnline.tooltip',
      showLastReportDate: true,
    };
  }
  if (
    onlineStatus === InstallationOnlineStatus.Online &&
    connectionStatus === InstallationConnectionStatus.Connected
  ) {
    return {
      color: 'success.main',
      statusLabelTranslationKey: 'servicePage.statusBar.connectedOnline.label',
      tooltipTranslationKey: 'servicePage.statusBar.connectedOnline.tooltip',
      showLastReportDate: true,
    };
  }
  return {
    color: 'error.main',
    statusLabelTranslationKey: 'servicePage.statusBar.unknown.label',
    tooltipTranslationKey: 'servicePage.statusBar.unknown.tooltip',
    showLastReportDate: false,
  };
};

interface InstallationStatusTooltipIconProps {
  tooltipColor: string;
  tooltipTranslationKey: string;
  lastReportDate?: string | null;
  showLastReportDate: boolean;
  onTooltipOpen?: (event: React.SyntheticEvent) => void;
  onTooltipClose?: (event: React.SyntheticEvent | Event) => void;
}

const InstallationStatusTooltipIcon = ({
  tooltipColor,
  lastReportDate,
  tooltipTranslationKey,
  showLastReportDate,
  onTooltipOpen,
  onTooltipClose,
}: InstallationStatusTooltipIconProps) => {
  const { t } = useTranslation('translation');
  const dateLocale = useDateLocale();

  return (
    <IconWithTooltip
      tooltipColor={tooltipColor}
      onOpen={onTooltipOpen}
      onClose={onTooltipClose}
      titleComponent={
        <Box sx={{ p: 0.5 }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 700, display: 'block' }}
            color="common.white"
          >
            {t(tooltipTranslationKey)}
          </Typography>
          {showLastReportDate && (
            <Typography
              variant="caption"
              sx={{ fontSize: '0.625rem' }}
              color="common.white"
            >
              {`${t('servicePage.statusBar.lastStatusReportLabel')} ${
                lastReportDate
                  ? `${formatDistance(new Date(lastReportDate), new Date(), {
                      locale: dateLocale,
                      addSuffix: true,
                      includeSeconds: true,
                    })} @ ${formatTimestamp(lastReportDate)}`
                  : '-'
              }`}
            </Typography>
          )}
        </Box>
      }
    />
  );
};

type InstallationStatusProps = {
  installationId?: string | number;
};

export const InstallationStatus = ({
  isLoading = false,
  installationStatusData,
  onTooltipOpen,
  onTooltipClose,
}: {
  isLoading?: boolean;
  installationStatusData: GetInstallationStatusResult | undefined;
  onTooltipOpen?: (event: React.SyntheticEvent) => void;
  onTooltipClose?: (event: React.SyntheticEvent | Event) => void;
}) => {
  const { t } = useTranslation('translation');
  const {
    color: statusColor,
    statusLabelTranslationKey,
    tooltipTranslationKey,
    showLastReportDate,
  } = transformInstallationStatusData(installationStatusData);

  if (isLoading) {
    return <Skeleton width={230} height={25} />;
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ width: 230, height: 25 }}>
      <Typography color={statusColor} sx={{ ml: 1, mr: 0.5, fontWeight: 700 }}>
        {t(statusLabelTranslationKey)}
      </Typography>
      <InstallationStatusTooltipIcon
        tooltipTranslationKey={tooltipTranslationKey}
        showLastReportDate={showLastReportDate}
        tooltipColor={statusColor}
        lastReportDate={installationStatusData?.lastRequest}
        onTooltipOpen={onTooltipOpen}
        onTooltipClose={onTooltipClose}
      />
    </Stack>
  );
};

export const ConnectedInstallationStatus = ({
  installationIdOrUuid,
  isLoading: isLoadingProp,
}: {
  installationIdOrUuid: string | number | null | undefined;
  isLoading?: boolean;
}) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { data, isLoading, refetch } = useGetInstallationStatusQuery(
    installationIdOrUuid ?? skipToken,
    {
      pollingInterval: shouldRefresh ? 15000 : 0,
      skipPollingIfUnfocused: true,
    },
  );
  const onTooltipOpen = useCallback(() => {
    setShouldRefresh(true);
    refetch();
  }, [refetch, setShouldRefresh]);
  const onTooltipClose = useCallback(() => {
    setShouldRefresh(false);
  }, [setShouldRefresh]);
  return (
    <InstallationStatus
      isLoading={isLoadingProp || isLoading}
      installationStatusData={data}
      onTooltipOpen={onTooltipOpen}
      onTooltipClose={onTooltipClose}
    />
  );
};

const InstallationStatusBar = ({ installationId }: InstallationStatusProps) => {
  const { t, i18n } = useTranslation('translation');

  const [now, setNow] = useState(new Date());

  const {
    data: installationStatusData,
    isLoading,
    isFetching,
    fulfilledTimeStamp,
  } = useGetInstallationStatusQuery(installationId ?? skipToken, {
    pollingInterval: 15000,
    skipPollingIfUnfocused: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setNow(new Date());
    const nowInterval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(nowInterval);
  }, [fulfilledTimeStamp]);

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" color="secondary.main">
          {t('servicePage.statusBar.statusLabel')}
        </Typography>
        <InstallationStatus
          isLoading={isLoading}
          installationStatusData={installationStatusData}
        />
        <Box display="flex" justifyContent="flex-end" sx={{ flex: 1 }}>
          {!!fulfilledTimeStamp && !isLoading && (
            <Typography color="secondary.main" align="right">
              {!i18n.language.startsWith('en') ? 'vor ' : ''}
              {Math.round(
                (now.getTime() - new Date(fulfilledTimeStamp).getTime()) / 1000,
              )}
              {i18n.language.startsWith('en') ? 's ago' : 's'} @{' '}
              {formatTimestamp(fulfilledTimeStamp)}
            </Typography>
          )}
          {isLoading && <Skeleton width={200} />}
        </Box>
      </Stack>
      <LinearProgress
        variant={isFetching ? 'indeterminate' : 'determinate'}
        value={100}
      />
    </Stack>
  );
};

export default InstallationStatusBar;
