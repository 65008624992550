import { Stack, Typography } from '@mui/material';
import isNil from 'lodash/isNil';
import React from 'react';
import { usePrevious } from '../../../../lib/hooks';

type PowerNowItemProps = {
  Icon?: React.FC;
  label: string;
  value?: number | null;
  isSoc?: boolean;
};

const PowerNowItem = ({
  Icon,
  label,
  value,
  isSoc = false,
}: PowerNowItemProps) => {
  const prevValue = usePrevious(value);
  return (
    <Stack
      direction="row"
      gap={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        backgroundColor:
          prevValue && value !== prevValue ? 'primary.veryLight' : undefined,
      }}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        {Icon && <Icon />}
        <Typography color="secondary.main">{label}</Typography>
      </Stack>
      <Typography color="secondary.main">
        {!isNil(value)
          ? `${(Math.abs(value) / (isSoc ? 1 : 1000)).toFixed(isSoc ? 0 : 2)}${
              isSoc ? '%' : 'kW'
            }`
          : '-'}
      </Typography>
    </Stack>
  );
};

export default PowerNowItem;
