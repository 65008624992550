import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { useGetInstallationHemsSettingsQuery } from '../../../../redux/api/iotCloud';
import InstallationDataSection from '../InstallationDataSection';
import { getEmergencyPowerReserveRows } from '../helper';

type EmergencyPowerReserveDataProps = {
  installationId?: string | number;
};

const EmergencyPowerReserveData = ({
  installationId,
}: EmergencyPowerReserveDataProps) => {
  const { t } = useTranslation('translation');

  const { data } = useGetInstallationHemsSettingsQuery(
    installationId ?? skipToken,
  );

  const emergencyPowerReserveRows = getEmergencyPowerReserveRows(data, t);

  return <InstallationDataSection rows={emergencyPowerReserveRows} />;
};

export default EmergencyPowerReserveData;
