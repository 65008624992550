import { TableCell, TableCellProps } from '@mui/material';

export type CustomTableCellProps = {
  highlight?: boolean;
} & TableCellProps;

const CustomTableCell = ({ highlight, ...rest }: CustomTableCellProps) => (
  <TableCell
    sx={highlight ? { backgroundColor: 'primary.veryLight' } : undefined}
    {...rest}
  />
);

export default CustomTableCell;
