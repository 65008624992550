import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetInstallationDeletionStatusQuery,
  useGetInstallationHemsSettingsQuery,
} from '../../redux/api/iotCloud';
import { SimpleJobState } from '../../redux/api/iotCloud/typeCopy';
import { useGetProjectServiceDataQuery } from '../../redux/api/montageApi';
import {
  getCustomerDataRows,
  getEkdIdDataRows,
  getProjectDataRows,
  getAmpereIqDataRows,
  getEmergencyPowerReserveRows,
  getPriceBasedChargingRows,
  getSolarBasedCarChargingRows,
} from './InstallationDetailsPage/helper';
import InstallationActions from './InstallationDetailsPage/InstallationActions';
import InstallationDataSection from './InstallationDetailsPage/InstallationDataSection';
import LoadingCard from './InstallationDetailsPage/LoadingCard';
import InstallationDeletionCard from './MainPage/InstallationDeletionCard';
import PriceSignalSection from './InstallationDetailsPage/PriceSignalSection';
import InstallationDataTab from './InstallationDetailsPage/tabs/InstallationDataTab';
import InstallationStatusBar from './components/InstallationStatus';

const ProjectDetails = ({ projectId }: { projectId?: string | number }) => {
  const { t } = useTranslation('translation');

  const { data: serviceData, isLoading: isServiceDataLoading } =
    useGetProjectServiceDataQuery(projectId ?? skipToken);
  const installationId = serviceData?.installation?.id;
  const { data: installationDeletionStatus } =
    useGetInstallationDeletionStatusQuery(installationId ?? skipToken);
  const { data: hemsSettingsData, isLoading: isHemsSettingsDataLoading } =
    useGetInstallationHemsSettingsQuery(installationId ?? skipToken);

  const customerDataRows = getCustomerDataRows(serviceData?.customer, t);
  const ekdIdDataRows = getEkdIdDataRows(serviceData?.customer?.ekdIdStatus, t);
  const projectDataRows = getProjectDataRows(projectId ?? '', serviceData, t);
  const ampereIqDataRows = getAmpereIqDataRows(serviceData?.installation, t);
  const priceBasedChargingDataRows = getPriceBasedChargingRows(
    hemsSettingsData,
    t,
  );
  const solarBasedCarChargingDataRows = getSolarBasedCarChargingRows(
    hemsSettingsData,
    t,
  );
  const emergencyPowerReserveDataRows = getEmergencyPowerReserveRows(
    hemsSettingsData,
    t,
  );

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12}>
        <LoadingCard>
          <InstallationStatusBar installationId={installationId} />
        </LoadingCard>
      </Grid>
      {installationDeletionStatus?.state && (
        <Grid item xs={12}>
          <InstallationDeletionCard
            title={t(
              'servicePortal.installationDeletion.titleInstallationInDeletion',
            )}
            highlight={status =>
              `${status.installationId}` === `${installationId}`
            }
            filter={status =>
              `${status.installationId}` === `${installationId}` ||
              [SimpleJobState.queued, SimpleJobState.processing].includes(
                status.state ?? '',
              )
            }
          />
        </Grid>
      )}
      <Grid item sm={12} md={6} lg={5}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationDataSection
            title={t('servicePage.customerData.title')}
            rows={customerDataRows}
          />
          <InstallationDataSection
            title={t('servicePage.customerAccountData.title')}
            rows={ekdIdDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6} lg={5}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationDataSection
            title={t('servicePage.projectData.title')}
            rows={projectDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={2}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationActions
            projectId={projectId}
            installationId={installationId}
            serviceData={serviceData}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={8} lg={installationId ? 4 : 12}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationDataSection
            title={t('servicePage.installationData.title')}
            rows={ampereIqDataRows}
          />
        </LoadingCard>
      </Grid>
      {Boolean(installationId) && (
        <>
          <Grid item sm={12} md={6} lg={4}>
            <InstallationDataTab installationId={installationId} />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <LoadingCard isLoading={isHemsSettingsDataLoading}>
              <Typography variant="h5" sx={{ color: 'secondary.main', mb: 2 }}>
                {t('servicePortal.installationDetailsPage.hemsSettings')}
              </Typography>
              <InstallationDataSection
                title={t(
                  'servicePortal.installationDetailsPage.priceBasedCharging',
                )}
                rows={priceBasedChargingDataRows}
              />
              <InstallationDataSection
                title={t(
                  'servicePortal.installationDetailsPage.solarBasedCarCharging',
                )}
                rows={solarBasedCarChargingDataRows}
              />
              <InstallationDataSection
                title={t(
                  'servicePortal.installationDetailsPage.emergencyPowerReserve',
                )}
                rows={emergencyPowerReserveDataRows}
              />
            </LoadingCard>
          </Grid>
          <Grid item sm={12}>
            <LoadingCard>
              <PriceSignalSection installationId={installationId} />
            </LoadingCard>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProjectDetails;
