import { Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { useGetProjectServiceDataQuery } from '../../../../redux/api/montageApi';
import InstallationActions from '../InstallationActions';
import LoadingCard from '../LoadingCard';
import InstallationDataSection from '../InstallationDataSection';
import {
  getAmpereIqDataRows,
  getCustomerDataRows,
  getEkdIdDataRows,
  getProjectDataRows,
} from '../helper';
import InstallationDeletionCard from '../../MainPage/InstallationDeletionCard';
import {
  useGetInstallationDeletionStatusQuery,
  useGetInstallationQuery,
} from '../../../../redux/api/iotCloud';
import { SimpleJobState } from '../../../../redux/api/iotCloud/typeCopy';

type InstallationManagementTabProps = {
  installationId?: string;
};

const InstallationManagementTab = ({
  installationId,
}: InstallationManagementTabProps) => {
  const { t } = useTranslation('translation');

  const { data: installation } = useGetInstallationQuery(
    installationId ?? skipToken,
  );
  const { data: installationDeletionStatus } =
    useGetInstallationDeletionStatusQuery(installationId ?? skipToken);
  const { data: serviceData, isLoading } = useGetProjectServiceDataQuery(
    installation?.projectId ?? skipToken,
  );

  const customerDataRows = getCustomerDataRows(serviceData?.customer, t);
  const ekdIdDataRows = getEkdIdDataRows(serviceData?.customer?.ekdIdStatus, t);
  const projectDataRows = getProjectDataRows(
    installation?.projectId ?? '',
    serviceData,
    t,
  );
  const ampereIqDataRows = getAmpereIqDataRows(serviceData?.installation, t);

  return (
    <Grid container spacing={3} mt={1}>
      {installationDeletionStatus?.state && (
        <Grid item xs={12}>
          <InstallationDeletionCard
            title={t(
              'servicePortal.installationDeletion.titleInstallationInDeletion',
            )}
            highlight={status =>
              `${status.installationId}` === `${installationId}`
            }
            filter={status =>
              `${status.installationId}` === `${installationId}` ||
              [SimpleJobState.queued, SimpleJobState.processing].includes(
                status.state ?? '',
              )
            }
          />
        </Grid>
      )}
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isLoading}>
          <InstallationDataSection
            title={t('servicePage.installationData.title')}
            rows={ampereIqDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isLoading}>
          <InstallationActions
            projectId={installation?.projectId}
            installationId={installationId}
            serviceData={serviceData}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isLoading}>
          <InstallationDataSection
            title={t('servicePage.customerData.title')}
            rows={customerDataRows}
          />
          <InstallationDataSection
            title={t('servicePage.customerAccountData.title')}
            rows={ekdIdDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isLoading}>
          <InstallationDataSection
            title={t('servicePage.projectData.title')}
            rows={projectDataRows}
          />
        </LoadingCard>
      </Grid>
    </Grid>
  );
};

export default InstallationManagementTab;
