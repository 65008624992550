import {
  TableCellProps,
  Stack,
  Typography,
  TableCell,
  Link,
  Skeleton,
} from '@mui/material';
import { isNil } from 'lodash';
import { Fragment, useCallback } from 'react';

type CellProps = {
  data?: string | number | null | undefined;
  linkUrl?: string | null;
  header?: boolean;
  highlighText?: string | number | undefined;
  isLoading?: boolean;
  children?: React.ReactNode;
} & TableCellProps;

const Cell = ({
  data,
  linkUrl,
  header,
  highlighText,
  isLoading,
  ...tableCellRest
}: CellProps) => {
  let textComponent: any = data;
  if (highlighText && data && ['string', 'number'].includes(typeof data)) {
    // split string by highlight text
    const parts = `${data}`.split(
      new RegExp(`${highlighText}`.replaceAll(' ', '|'), 'gi'),
    );
    const matches = `${data}`.match(
      new RegExp(`${highlighText}`.replaceAll(' ', '|'), 'gi'),
    );
    textComponent = (
      <Stack direction="row">
        {parts.map((part, index) => (
          <Fragment key={`${part}-${index}`}>
            <Typography>{part}</Typography>
            {Boolean(parts.length > index + 1) && (
              <Typography sx={{ backgroundColor: 'primary.light' }}>
                {matches?.[index]}
              </Typography>
            )}
          </Fragment>
        ))}
      </Stack>
    );
  }

  const getDataComponent = useCallback(() => {
    if (isLoading) {
      return <Skeleton variant="text" />;
    }
    if (isNil(data)) {
      return '-';
    }
    if (linkUrl) {
      return (
        <Link
          href={linkUrl}
          onClick={ev => {
            ev.stopPropagation();
          }}
          underline="none"
          target="_blank"
        >
          {textComponent}
        </Link>
      );
    }
    return textComponent;
  }, [data, isLoading, linkUrl, textComponent]);

  return (
    <TableCell {...tableCellRest}>
      {header ? (
        <Typography variant="h6">{getDataComponent()}</Typography>
      ) : (
        getDataComponent()
      )}
    </TableCell>
  );
};

export default Cell;
