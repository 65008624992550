import { skipToken } from '@reduxjs/toolkit/query';
import isNumber from 'lodash/isNumber';
import RefreshIcon from '@mui/icons-material/Refresh';

import { ReactComponent as FromGridIcon } from '../../../../assets/icons/devices/fromGrid.svg';
import { ReactComponent as HeatpumpIcon } from '../../../../assets/icons/devices/heatpump.svg';
import { ReactComponent as PvIcon } from '../../../../assets/icons/devices/pv.svg';
import { ReactComponent as ToGridIcon } from '../../../../assets/icons/devices/toGrid.svg';
import { ReactComponent as FromBatteryIcon } from '../../../../assets/icons/devices/fromBattery.svg';
import { ReactComponent as ToBatteryIcon } from '../../../../assets/icons/devices/toBattery.svg';
import { ReactComponent as HeatingRodIcon } from '../../../../assets/icons/devices/heatingrod.svg';
import { ReactComponent as HouseIcon } from '../../../../assets/icons/devices/house.svg';
import { ReactComponent as WallboxIcon } from '../../../../assets/icons/devices/wallbox.svg';
import { ReactComponent as HeatIcon } from '../../../../assets/icons/devices/heat.svg';
import { ReactComponent as BatterySocIcon } from '../../../../assets/icons/devices/batterySoc.svg';
import { useGetInstallationPowerNowQuery } from '../../../../redux/api/iotCloud';
import LoadingCard from '../LoadingCard';
import PowerNowItem from './PowerNowItem';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from '../../../../lib/formatting';
import { useMemo } from 'react';

type PowerNowSectionProps = {
  installationId?: string | number;
};

const PowerNowSection = ({ installationId }: PowerNowSectionProps) => {
  const { t: t_global } = useTranslation('translation');
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.installationDetailsPage',
  });

  const { data, isLoading, isFetching, isError, fulfilledTimeStamp, refetch } =
    useGetInstallationPowerNowQuery(installationId ?? skipToken, {
      pollingInterval: 10000,
      skipPollingIfUnfocused: true,
    });
  const isLive = useMemo(() => {
    return (
      fulfilledTimeStamp &&
      new Date().getTime() - new Date(fulfilledTimeStamp).getTime() < 30000
    );
  }, [fulfilledTimeStamp]);

  const fromGrid =
    data && isNumber(data.gridPower) && data.gridPower >= 0
      ? data.gridPower
      : null;
  const toGrid =
    data && isNumber(data.gridPower) && data.gridPower < 0
      ? data.gridPower
      : null;
  const fromBattery =
    data && isNumber(data.batteryPower) && data.batteryPower >= 0
      ? data.batteryPower
      : null;
  const toBattery =
    data && isNumber(data.batteryPower) && data.batteryPower < 0
      ? data.batteryPower
      : null;
  const heat =
    !data || (!isNumber(data.heatPumpPower) && !isNumber(data.heatingRodPower))
      ? null
      : (data.heatPumpPower ?? 0) + (data.heatingRodPower ?? 0);

  if (isError) {
    return (
      <LoadingCard
        isLoading={isLoading}
        isLoadingWithContent={isFetching}
        title={t('currentPowerData')}
      >
        <Alert
          severity="error"
          sx={{ alignItems: 'center' }}
          action={
            <Button variant="outlined" color="error" onClick={refetch}>
              Retry
            </Button>
          }
        >
          {t_global('common.unknownErrorTryLater')}
        </Alert>
      </LoadingCard>
    );
  }

  return (
    <LoadingCard isLoading={isLoading} isLoadingWithContent={isFetching}>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid item xs={12}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h5" color="secondary.main">
              {t('currentPowerData')}
            </Typography>
            {isLive && (
              <Skeleton
                height={10}
                width={10}
                animation="pulse"
                variant="circular"
                sx={{ mr: 1, backgroundColor: 'green' }}
              />
            )}
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography color="secondary.main">
              {t('updatedAt')}: {formatTimestamp(fulfilledTimeStamp)}
            </Typography>
            <IconButton onClick={refetch}>
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" gap={2}>
            <PowerNowItem
              Icon={PvIcon}
              label={t('generated')}
              value={data?.pvPower}
            />
            <PowerNowItem
              Icon={FromGridIcon}
              label={t('fromGrid')}
              value={fromGrid}
            />
            <PowerNowItem
              Icon={FromBatteryIcon}
              label={t('used')}
              value={fromBattery}
            />
            <PowerNowItem
              Icon={BatterySocIcon}
              label={t('batterySoc')}
              value={data?.batterySoc}
              isSoc={true}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" gap={2}>
            <PowerNowItem
              Icon={HouseIcon}
              label={t('consumed')}
              value={data?.housePower}
            />
            <PowerNowItem
              Icon={ToGridIcon}
              label={t('intoGrid')}
              value={toGrid}
            />
            <PowerNowItem
              Icon={ToBatteryIcon}
              label={t('stored')}
              value={toBattery}
            />
            <PowerNowItem
              Icon={WallboxIcon}
              label={t('wallbox')}
              value={data?.wallboxPower}
            />
            <PowerNowItem Icon={HeatIcon} label={t('heating')} value={heat} />
            <PowerNowItem
              Icon={HeatpumpIcon}
              label={t('heatPump')}
              value={data?.heatPumpPower}
            />
            <PowerNowItem
              Icon={HeatingRodIcon}
              label={t('heatingRod')}
              value={data?.heatingRodPower}
            />
          </Stack>
        </Grid>
      </Grid>
    </LoadingCard>
  );
};

export default PowerNowSection;
