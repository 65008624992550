import {
  Card,
  CardHeader,
  CardContent,
  Skeleton,
  LinearProgress,
  Box,
} from '@mui/material';

type LoadingCardProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  isLoadingWithContent?: boolean;
  title?: string | null;
};

const LoadingCard = ({
  title,
  children,
  isLoading,
  isLoadingWithContent,
}: LoadingCardProps) => {
  return (
    <Card variant="elevation" sx={{ height: '100%' }}>
      {isLoadingWithContent || isLoading ? (
        <LinearProgress />
      ) : (
        <Box sx={{ height: 4 }}></Box>
      )}
      {title && <CardHeader title={title} />}
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={300} />
        ) : (
          children
        )}
      </CardContent>
    </Card>
  );
};

export default LoadingCard;
