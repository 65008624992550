import { Button, MenuItem, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '../../../../context/SnackbarContext';
import {
  useGetInstallationHemsSettingsQuery,
  usePatchInstallationHemsSettingsMutation,
} from '../../../../redux/api/iotCloud';
import FormNumberField from '../../../common/Inputs/FormNumberField';
import FormSelect from '../../../common/Inputs/FormSelect';

type FormInputs = {
  emergencyPowerEnabled: boolean;
  emergencyPowerLimit: string;
};

type EmergencyPowerReserveFormProps = {
  installationId?: string | number;
  onClose: () => void;
};

const EmergencyPowerReserveForm = ({
  installationId,
  onClose,
}: EmergencyPowerReserveFormProps) => {
  const { t } = useTranslation('translation');
  const { showSnackbar } = useSnackbar();

  const { data } = useGetInstallationHemsSettingsQuery(
    installationId ?? skipToken,
  );
  const [patchHemsSettings] = usePatchInstallationHemsSettingsMutation();

  const { control, watch, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      emergencyPowerEnabled: data?.emergencyPowerEnabled ?? false,
      emergencyPowerLimit: data?.emergencyPowerLimit?.toString() ?? '',
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = data => {
    if (!installationId) {
      return;
    }
    const emergencyPowerLimit =
      data.emergencyPowerEnabled !== true
        ? null
        : parseInt(data.emergencyPowerLimit);

    const body = {
      emergencyPowerEnabled: data.emergencyPowerEnabled,
      emergencyPowerLimit,
    };

    patchHemsSettings({
      installationId,
      body,
    })
      .unwrap()
      .catch(() => {
        showSnackbar(
          t(
            'servicePortal.installationDetailsPage.emergencyPowerReserveUpdateError',
          ),
          'error',
        );
      });

    onClose();
  };

  return (
    <Stack direction="column" alignItems="flex-start" gap={2}>
      <FormSelect
        name="emergencyPowerEnabled"
        control={control}
        selectProps={{ sx: { minWidth: 200 }, label: t('common.enabled') }}
      >
        <MenuItem value={true as any}>{t('common.yes')}</MenuItem>
        <MenuItem value={false as any}>{t('common.no')}</MenuItem>
      </FormSelect>
      <FormNumberField
        disabled={watch('emergencyPowerEnabled') !== true}
        name="emergencyPowerLimit"
        control={control}
        rules={{
          required: t('validation.required') ?? '',
          min: { value: 1, message: `${t('validation.min')} 1` },
          max: { value: 100, message: `${t('validation.max')} 100` },
        }}
        textFieldProps={{
          sx: { minWidth: 200 },
          label: t('servicePortal.installationDetailsPage.limit'),
        }}
      />
      <Stack direction="row" gap={1}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default EmergencyPowerReserveForm;
