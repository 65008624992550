import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SearchResultItem } from '../../../redux/api/iotCloud/types';
import Cell from '../../common/table/Cell';
import { ConnectedInstallationStatus } from '../components/InstallationStatus';
import { isEmpty, isNil } from 'lodash';
import EmptyTableBody from '../../common/table/EmptyTableBody';
import {
  useGetIoTCloudMgmtSearchProjectQuery,
  useGetIoTCloudProjectInfoQuery,
} from '../../../redux/api/iotCloud';
import { skipToken } from '@reduxjs/toolkit/query';

const SearchResultTableRow = ({
  result,
  filters,
}: {
  result: SearchResultItem;
  filters: {
    phrase?: string;
    [key: string]: any;
  };
}) => {
  const navigate = useNavigate();
  const { id, projectId } = result;

  const handleRowPress = (event: MouseEvent<HTMLTableRowElement>) => {
    const { installationId, projectId } = event.currentTarget.dataset;
    if (installationId) {
      navigate(`/servicePortal/installation/${installationId}`);
    } else if (projectId) {
      navigate(`/servicePortal/project/${projectId}`);
    }
  };

  // if the search result does not contain installation or userAccount yet, fetch it
  const skipProjectInfo =
    isNil(projectId) ||
    !isNil(result.installation?.id) ||
    !isNil(result.userAccount?.id);
  const { data: iotData, isLoading: isLoadingProjectInfo } =
    useGetIoTCloudProjectInfoQuery(skipProjectInfo ? skipToken : projectId);

  // if the search result does not the searchProject from mgmt yet, fetch it
  const skipMgmtSearchProject = isNil(projectId) || !isNil(result?.project?.id);
  const { data: mgmtProjectData, isLoading: isLoadingMgmtProjectData } =
    useGetIoTCloudMgmtSearchProjectQuery(
      skipMgmtSearchProject ? skipToken : projectId,
    );

  // assmble the data from original result and lazy loaded data
  const installation = result?.installation ?? iotData?.installation;
  const userAccount = result?.userAccount ?? iotData?.userAccount;
  const project = result?.project ?? mgmtProjectData;
  const isLoading = isLoadingProjectInfo || isLoadingMgmtProjectData;

  return (
    <TableRow
      key={id}
      hover
      data-id={id}
      data-project-id={projectId ?? installation?.projectId ?? project?.id}
      data-installation-id={installation?.id}
      onClick={handleRowPress}
      sx={{ cursor: 'pointer' }}
    >
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={installation?.id}
      />
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={project?.id}
      />
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={project?.customerNumber}
      />
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={project?.title}
      />
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={project?.customerAddress}
      />
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={[
          project?.customerPhoneNumber ?? '',
          project?.customerEmail ?? '',
        ].join(' ')}
      />
      <Cell
        highlighText={filters.phrase}
        isLoading={isLoading}
        data={installation?.kgGatewaySerialNumber}
      />
      <Cell
        isLoading={isLoading}
        highlighText={filters.phrase}
        data={userAccount?.kgUsername}
      />
      <TableCell>
        {isNil(installation?.id) && !isLoading ? (
          '-'
        ) : (
          <ConnectedInstallationStatus
            isLoading={isLoading}
            installationIdOrUuid={installation?.id}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const SearchResultTable = ({
  results,
  filters,
}: {
  results?: SearchResultItem[];
  filters: {
    phrase?: string;
    [key: string]: any;
  };
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.search',
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Cell header colSpan={1} data={t('installation')} />
            <Cell header colSpan={5} data={t('project')} />
            <Cell header colSpan={3} data={t('kiwigrid')} />
          </TableRow>
          <TableRow>
            <Cell header data={t('installationId')} />
            <Cell header data={t('projectId')} />
            <Cell header data={t('customerNumber')} />
            <Cell header data={t('projectTitle')} />
            <Cell header data={t('projectAddress')} />
            <Cell header data={t('customerContact')} />
            <Cell header data={t('kgGatewaySerialNumber')} />
            <Cell header data={t('kgUsername')} />
            <Cell header data={t('status')} />
          </TableRow>
        </TableHead>
        <TableBody>
          {results &&
            !isEmpty(results) &&
            results.map(result => (
              <SearchResultTableRow
                key={result.id}
                result={result}
                filters={filters}
              />
            ))}
          {!results ||
            (isEmpty(results) && <EmptyTableBody numberOfColumns={9} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchResultTable;
