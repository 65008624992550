import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';
import { formatTimestamp } from '../../../lib/formatting';
import { Installation } from '../../../redux/api/iotCloud/types';
import Cell from '../../common/table/Cell';
import { ConnectedInstallationStatus } from '../components/InstallationStatus';

type InstallationsTableProps = {
  installations?: Installation[];
  filters: {
    projectId?: string | number;
    partialKgGatewaySerialNumber?: string;
    [key: string]: any;
  };
};

const InstallationsTable = ({
  installations,
  filters,
}: InstallationsTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.installationPage',
  });
  const navigate = useNavigate();

  const handleRowPress = (event: MouseEvent<HTMLTableRowElement>) => {
    const { installationId } = event.currentTarget.dataset;
    navigate(`/servicePortal/installation/${installationId}`);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Cell header data="ID" />
            <Cell header data={t('projectId')} />
            <Cell header data={t('location')} />
            <Cell header data={t('gatewayType')} />
            <Cell header data={t('kgGatewaySerialNumber')} />
            <Cell header data={t('created')} />
            <Cell header data={t('updated')} />
            <Cell header data={t('status')} />
          </TableRow>
        </TableHead>
        <TableBody>
          {installations &&
            installations.map(
              ({
                id,
                projectId,
                lat,
                lng,
                gatewayType,
                kgGatewaySerialNumber,
                createdAt,
                updatedAt,
              }) => {
                return (
                  <TableRow
                    key={id}
                    hover
                    data-project-id={projectId}
                    data-installation-id={id}
                    onClick={handleRowPress}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Cell highlighText={filters.installationId} data={id} />
                    <Cell highlighText={filters.projectId} data={projectId} />
                    <Cell
                      data={`${lat}, ${lng}`}
                      linkUrl={config.servicePage.googleMapsLinkByCoordinates(
                        lat,
                        lng,
                      )}
                    />
                    <Cell data={gatewayType} />
                    <Cell
                      highlighText={filters.partialKgGatewaySerialNumber}
                      data={kgGatewaySerialNumber}
                    />
                    <Cell data={formatTimestamp(createdAt)} />
                    <Cell data={formatTimestamp(updatedAt)} />
                    <TableCell>
                      <ConnectedInstallationStatus installationIdOrUuid={id} />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstallationsTable;
