import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { HyperTableWithSize } from '../../../redux/api/iotCloud/typeCopy';
import { formatBytes } from '../../../lib/formatting';
import { orderBy } from 'lodash';

type CellProps = {
  data: string | number | null | undefined;
  linkUrl?: string | null;
  header?: boolean;
};

const Cell = ({ data, linkUrl, header }: CellProps) => {
  const dataComponent = data ? (
    linkUrl ? (
      <Link
        href={linkUrl}
        onClick={ev => {
          ev.stopPropagation();
        }}
        underline="none"
        target="_blank"
      >
        {data}
      </Link>
    ) : (
      data
    )
  ) : (
    '-'
  );

  return (
    <TableCell>
      {header ? (
        <Typography variant="h6">{dataComponent}</Typography>
      ) : (
        dataComponent
      )}
    </TableCell>
  );
};

type HyperTableTableProps = {
  hyperTables?: HyperTableWithSize[];
};

const HyperTableTable = ({ hyperTables }: HyperTableTableProps) => {
  const navigate = useNavigate();

  const handleRowPress = (event: MouseEvent<HTMLTableRowElement>) => {
    const { hyperTableName } = event.currentTarget.dataset;
    navigate(`${hyperTableName}`);
  };

  const sorted = orderBy(
    hyperTables,
    [h => Number(h.hypertable_size), h => h.hypertable_name],
    ['desc'],
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Cell header data="Schema" />
            <Cell header data="Table" />
            <Cell header data="Size" />
            <Cell header data="Dimensions" />
            <Cell header data="Chunks" />
            <Cell header data="Compression" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted &&
            sorted.map(
              ({
                hypertable_schema,
                hypertable_name,
                hypertable_size,
                num_dimensions,
                num_chunks,
                compression_enabled,
              }) => {
                return (
                  <TableRow
                    key={hypertable_name}
                    hover
                    data-hyper-table-name={hypertable_name}
                    onClick={handleRowPress}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Cell data={hypertable_schema} />
                    <Cell data={hypertable_name} />
                    <Cell
                      data={
                        hypertable_size ? formatBytes(hypertable_size) : '-'
                      }
                    />
                    <Cell data={num_dimensions} />
                    <Cell data={num_chunks} />
                    <Cell data={compression_enabled ? 'Y' : 'N'} />
                  </TableRow>
                );
              },
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HyperTableTable;
