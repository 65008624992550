import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDuration } from 'date-fns';
import { parse } from 'iso8601-duration';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { formatTimestamp } from '../../../../lib/formatting';
import {
  GetBatteryChargeHistoryItem,
  GetBatteryChargeHistoryResult,
  HemsBatteryChargingStrategy,
} from '../../../../redux/api/iotCloud/types';
import CustomTableCell from '../../../common/CustomTableCell';
import { TFunction } from 'i18next';

const EmptyTableBody = ({ numberOfColumns }: { numberOfColumns: number }) => {
  const { t } = useTranslation('translation');

  return (
    <TableRow>
      <CustomTableCell colSpan={numberOfColumns} sx={{ textAlign: 'center' }}>
        <Typography color="secondary.main" variant="h6">
          {t('servicePage.historyTable.noData')}
        </Typography>
      </CustomTableCell>
    </TableRow>
  );
};

type TableHeaderCellProps = {
  title: string;
  subtitle: string;
};

const PriceSignalTableHeaderCell = ({
  title,
  subtitle,
}: TableHeaderCellProps) => {
  return (
    <CustomTableCell sx={{ verticalAlign: 'top' }}>
      <Typography
        color="secondary.main"
        variant="h6"
        sx={{ textAlign: 'left', whiteSpace: 'pre-line' }}
      >
        {title}
      </Typography>
      <Typography
        color="secondary.main"
        sx={{ textAlign: 'left', whiteSpace: 'pre-line' }}
      >
        {subtitle}
      </Typography>
    </CustomTableCell>
  );
};

const getHemsSettingRowText = (
  t: TFunction<'translation', undefined, 'translation'>,
  row: GetBatteryChargeHistoryItem,
): string => {
  const {
    hemsSettingChangeAt,
    hemsSettingBatteryChargingEnabled,
    hemsSettingBatteryChargingStrategy,
    hemsSettingBatteryChargingMode,
    hemsSettingBatteryChargingPriceLimit,
    hemsSettingBatteryChargingThreshold,
  } = row;

  if (!hemsSettingChangeAt) {
    return t('servicePage.noHemsSettingsYet') ?? '';
  }
  const changeAtText = `@ ${formatTimestamp(hemsSettingChangeAt)}`;
  if (!hemsSettingBatteryChargingEnabled) {
    return `${t(
      'servicePortal.priceSignalCard.batteryChargingDisabled',
    )} ${changeAtText}`;
  }
  if (
    hemsSettingBatteryChargingStrategy === HemsBatteryChargingStrategy.Dynamic
  ) {
    return `${t(
      'servicePortal.priceSignalCard.dynamicCharging',
    )} ${changeAtText}`;
  }
  return `${hemsSettingBatteryChargingThreshold}%, ${hemsSettingBatteryChargingPriceLimit}cent, ${hemsSettingBatteryChargingMode} ${changeAtText}`;
};

const PriceSignalTableRow = ({
  row,
  highlights,
}: {
  row: GetBatteryChargeHistoryItem;
  highlights: Record<string, boolean>;
}) => {
  const { t } = useTranslation('translation');

  const {
    timestamp,
    batteryId,
    chargingMode,
    relativeChargePower,
    soc,
    electricityPrice,
    prevOfflineSince,
    prevOfflineFor,
  } = row;
  const hemsSettingRowText = getHemsSettingRowText(t, row);

  return (
    <TableRow key={`${timestamp}-${batteryId}`}>
      <CustomTableCell>{formatTimestamp(timestamp)}</CustomTableCell>
      <CustomTableCell>{batteryId}</CustomTableCell>
      <CustomTableCell
        highlight={highlights.chargingMode || highlights.relativeChargePower}
      >
        {`${chargingMode}${
          relativeChargePower ? `: ${relativeChargePower}%` : ''
        }`}
      </CustomTableCell>
      <CustomTableCell>{`${soc}%, ${electricityPrice}cent`}</CustomTableCell>
      <CustomTableCell highlight={highlights.hemsSettingChangeAt}>
        {hemsSettingRowText}
      </CustomTableCell>
      <CustomTableCell highlight={!!prevOfflineSince}>
        {`${prevOfflineSince ? formatTimestamp(prevOfflineSince) : ''} ${
          prevOfflineFor
            ? `(${formatDuration(parse(prevOfflineFor), {
                format: ['years', 'months', 'days', 'hours', 'minutes'],
              })})`
            : ''
        }`}
      </CustomTableCell>
    </TableRow>
  );
};

type PriceSignalTableProps = {
  data?: GetBatteryChargeHistoryResult['data'];
};

const PriceSignalTable = ({ data }: PriceSignalTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePage.historyTable.headers',
  });

  const noData = !data || isEmpty(data);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <PriceSignalTableHeaderCell
            title={t('commandTimestamp.title')}
            subtitle={t('commandTimestamp.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('batterySerialNumber.title')}
            subtitle={t('batterySerialNumber.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('command.title')}
            subtitle={t('command.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('conditions.title')}
            subtitle={t('conditions.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('hemsSettings.title')}
            subtitle={t('hemsSettings.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('previousOffline.title')}
            subtitle={t('previousOffline.subtitle')}
          />
        </TableRow>
      </TableHead>
      <TableBody>
        {noData && <EmptyTableBody numberOfColumns={6} />}
        {!noData &&
          data.map((row, i) => {
            const next = data
              .slice(i + 1)
              .find(r => r.batteryId === row.batteryId);
            const highlights = Object.entries(next ?? {}).reduce(
              (acc, [key, value]) => {
                if (value !== (row as Record<string, any>)[key]) {
                  acc[key] = true;
                }
                return acc;
              },
              {} as Record<string, boolean>,
            );

            return (
              <PriceSignalTableRow
                key={`${row.timestamp}-${row.batteryId}`}
                row={row}
                highlights={highlights}
              />
            );
          })}
      </TableBody>
    </Table>
  );
};

export default PriceSignalTable;
