import { Box, LinearProgress, Typography } from '@mui/material';
import { useGetHyperTableChunksQuery } from '../../../redux/api/iotCloud';
import ServicePortalLayout from '../ServicePortalLayout';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import HyperTableChunkTable from './HyperTableChunkTable';

const HyperTableDetailsPage = () => {
  const { hyperTableName } = useParams();
  const { data, isFetching } = useGetHyperTableChunksQuery(
    hyperTableName ?? skipToken,
  );

  return (
    <ServicePortalLayout>
      <Box
        sx={{
          my: 1,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      <Typography
        variant="h3"
        sx={{ pb: 5 }}
      >{`HyperTable ${hyperTableName} Chunks`}</Typography>
      <HyperTableChunkTable chunks={data} />
    </ServicePortalLayout>
  );
};

export default HyperTableDetailsPage;
