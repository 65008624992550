import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SendIcon } from '../../../../../assets/icons/send.svg';
import ButtonWithIcon from '../../../../common/ButtonWithIcon';
import EkdIdReminderEmailDialog from './EkdIdReminderEmailDialog';

interface EkdIdReminderEmailDialogTriggerButtonProps {
  projectId: string | number;
  emailAddress: string | null;
  disabled?: boolean;
}

const EkdIdReminderEmailDialogTriggerButton = ({
  projectId,
  disabled = false,
  emailAddress,
}: EkdIdReminderEmailDialogTriggerButtonProps) => {
  const { t } = useTranslation('translation');
  const [isOpen, setIsOpen] = useState(false);
  const [wasEmailSent, setWasEmailSent] = useState(false);

  const toggleDialogVisibility = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const onSuccess = () => {
    setWasEmailSent(true);
  };

  return (
    <>
      <Box>
        <ButtonWithIcon
          colorVariant="secondary"
          disabled={disabled || !emailAddress || wasEmailSent}
          variant="contained"
          onClick={toggleDialogVisibility}
          icon={<SendIcon fill="white" />}
        >
          {t('servicePage.ekdIdReminderDialog.triggerButtonLabel')}
        </ButtonWithIcon>
      </Box>
      {emailAddress && (
        <EkdIdReminderEmailDialog
          isOpen={isOpen}
          onSuccess={onSuccess}
          onClose={toggleDialogVisibility}
          projectId={projectId}
          emailAddress={emailAddress}
        />
      )}
    </>
  );
};

export default EkdIdReminderEmailDialogTriggerButton;
