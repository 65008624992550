import { combineReducers, configureStore } from '@reduxjs/toolkit';
import iotCloudApi from './api/iotCloud';
import montageApi from './api/montageApi';
import authSlice from './auth';
import servicePortalSlice from './servicePortal';

const rootReducer = combineReducers({
  [iotCloudApi.reducerPath]: iotCloudApi.reducer,
  [montageApi.reducerPath]: montageApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [servicePortalSlice.name]: servicePortalSlice.reducer,
});

const middlewares = [iotCloudApi.middleware, montageApi.middleware];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middlewares),
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
