import { Box, LinearProgress, Pagination } from '@mui/material';
import isNil from 'lodash/isNil';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetInstallationsQuery } from '../../../redux/api/iotCloud';
import { GetInstallationsArgs } from '../../../redux/api/iotCloud/types';
import ServicePortalLayout from '../ServicePortalLayout';
import InstallationFilters from './InstallationsFilters';
import InstallationsTable from './InstallationsTable';

export const DEFAULT_RECORDS_PER_PAGE = 25;

const InstallationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryArgs = useMemo(() => {
    const installationId = searchParams.get('installationId');
    const projectId = searchParams.get('projectId');
    const limit = searchParams.get('limit');
    const offset = searchParams.get('offset');
    const partialKgGatewaySerialNumber = searchParams.get(
      'partialKgGatewaySerialNumber',
    );
    const hasCoordinates = searchParams.get('hasCoordinates');
    return {
      installationId: installationId ? parseInt(installationId) : undefined,
      projectId: projectId ? parseInt(projectId) : undefined,
      limit: limit ? parseInt(limit) : DEFAULT_RECORDS_PER_PAGE,
      offset: offset ? parseInt(offset) : undefined,
      partialKgGatewaySerialNumber: partialKgGatewaySerialNumber ?? undefined,
      hasCoordinates: hasCoordinates ?? undefined,
    };
  }, [searchParams]);

  const { data: installationsData, isFetching } =
    useGetInstallationsQuery(queryArgs);

  const handleSearchParamsChange = useCallback(
    (key: keyof GetInstallationsArgs, value?: string | number) => {
      setSearchParams(previous => {
        const newParams = new URLSearchParams(previous);
        newParams.set(key, isNil(value) ? '' : value.toString());
        if (key !== 'offset') {
          newParams.set('offset', '0');
        }
        return newParams;
      });
    },
    [setSearchParams],
  );

  const handlePageChange = (_: unknown, page: number) => {
    handleSearchParamsChange('offset', (page - 1) * queryArgs.limit);
  };

  const currentPage = (queryArgs.offset ?? 0) / queryArgs.limit + 1;
  const pageCount = installationsData
    ? Math.ceil(installationsData.meta.total / queryArgs.limit)
    : null;

  return (
    <ServicePortalLayout>
      <InstallationFilters onChange={handleSearchParamsChange} />
      <Box
        sx={{
          my: 1,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      <InstallationsTable
        installations={installationsData?.data}
        filters={queryArgs}
      />
      {!!pageCount && (
        <Pagination
          sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
          page={currentPage}
          onChange={handlePageChange}
          count={pageCount}
          variant="outlined"
          shape="rounded"
          hidePrevButton
          hideNextButton
        />
      )}
    </ServicePortalLayout>
  );
};

export default InstallationPage;
