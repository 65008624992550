import { TableBody, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatTimestamp } from '../../../../lib/formatting';
import {
  GetHemsSettingsAuditResult,
  HemsBatteryChargingMode,
  HemsBatteryChargingStrategy,
} from '../../../../redux/api/iotCloud/types';
import CustomTableCell from '../../../common/CustomTableCell';

type HemsSettingsAuditTableBodyProps = {
  data: GetHemsSettingsAuditResult['data'];
};

const HemsSettingsAuditTableBody = ({
  data,
}: HemsSettingsAuditTableBodyProps) => {
  const { t } = useTranslation('translation');

  return (
    <TableBody>
      {data.map((hemsSettingAudit, index) => {
        const next = data[index + 1];
        const highlights = Object.entries(next ?? {}).reduce(
          (acc, [key, value]) => {
            if (value !== (hemsSettingAudit as Record<string, any>)[key]) {
              acc[key] = true;
            }
            return acc;
          },
          {} as Record<string, boolean>,
        );

        const {
          id,
          changeType,
          changeAt,
          changeBy,
          batteryChargingEnabled,
          batteryChargingThreshold,
          batteryChargingMode,
          batteryChargingStrategy,
          batteryChargingPriceLimit,
          evChargingEnabled,
          evChargingSnoozeUntilTimestamp,
          emergencyPowerEnabled,
          emergencyPowerLimit,
        } = hemsSettingAudit;

        return (
          <TableRow key={id}>
            <CustomTableCell>{changeType}</CustomTableCell>
            <CustomTableCell>{formatTimestamp(changeAt)}</CustomTableCell>
            <CustomTableCell>{changeBy}</CustomTableCell>
            <CustomTableCell highlight={highlights.batteryChargingEnabled}>
              {batteryChargingEnabled ? t('common.yes') : t('common.no')}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.batteryChargingThreshold}>
              {batteryChargingThreshold ? `${batteryChargingThreshold}%` : '-'}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.batteryChargingMode}>
              {!batteryChargingMode
                ? '-'
                : batteryChargingMode === HemsBatteryChargingMode.Fast
                ? t('servicePortal.installationDetailsPage.fast')
                : t('servicePortal.installationDetailsPage.gentle')}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.batteryChargingStrategy}>
              {!batteryChargingStrategy
                ? '-'
                : batteryChargingStrategy ===
                  HemsBatteryChargingStrategy.Dynamic
                ? t('servicePortal.installationDetailsPage.dynamic')
                : t('servicePortal.installationDetailsPage.manual')}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.batteryChargingPriceLimit}>
              {batteryChargingPriceLimit
                ? `${batteryChargingPriceLimit} cent`
                : '-'}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.evChargingEnabled}>
              {evChargingEnabled ? t('common.yes') : t('common.no')}
            </CustomTableCell>
            <CustomTableCell
              highlight={highlights.evChargingSnoozeUntilTimestamp}
            >
              {evChargingSnoozeUntilTimestamp
                ? formatTimestamp(evChargingSnoozeUntilTimestamp)
                : '-'}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.emergencyPowerEnabled}>
              {emergencyPowerEnabled ? t('common.yes') : t('common.no')}
            </CustomTableCell>
            <CustomTableCell highlight={highlights.emergencyPowerLimit}>
              {emergencyPowerLimit ? `${emergencyPowerLimit}%` : '-'}
            </CustomTableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default HemsSettingsAuditTableBody;
