import { DialogContent, TextField, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckmarkIcon } from '../../../../../assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ex.svg';
import DialogFooter from '../../../../common/dialog/DialogFooter';

export type GatewayConnectionDialogInputs = {
  serialNumber: string;
  gatewaySecret: string;
};

interface GatewayConnectionDialogFirstStageContentProps {
  projectId: string | number;
  customerNumber: string | null;
  onInputChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: keyof GatewayConnectionDialogInputs,
  ) => void;
  inputs: GatewayConnectionDialogInputs;
  onClose: () => void;
  onConfirm: () => void;
}

const GatewayConnectionDialogFirstStageContent = ({
  projectId,
  customerNumber,
  onInputChange,
  inputs,
  onClose,
  onConfirm,
}: GatewayConnectionDialogFirstStageContentProps) => {
  const { t } = useTranslation('translation');
  const { serialNumber, gatewaySecret } = inputs;
  const nextButtonDisabled = serialNumber === '';

  return (
    <DialogContent>
      {customerNumber && (
        <>
          <Typography sx={{ fontWeight: 700 }}>
            {t('servicePage.gatewayConnectionDialog.customerNumber')}
          </Typography>
          <Typography sx={{ mt: 1 }} color="secondary.main">
            {customerNumber}
          </Typography>
        </>
      )}
      <Typography sx={{ fontWeight: 700 }}>
        {t('servicePage.gatewayConnectionDialog.projectId')}
      </Typography>
      <Typography sx={{ mt: 1 }} color="secondary.main">
        {projectId}
      </Typography>
      <Typography sx={{ fontWeight: 700, mt: 2 }}>
        {t('servicePage.gatewayConnectionDialog.ampereSerialNumber')}
      </Typography>
      <TextField
        label={t('servicePage.gatewayConnectionDialog.serialNumberFieldLabel')}
        onChange={e => {
          onInputChange(e, 'serialNumber');
        }}
        value={serialNumber}
        sx={{ mt: 2 }}
        size="small"
        fullWidth
      />
      <Typography sx={{ fontWeight: 700, mt: 2 }}>
        {t('servicePage.gatewayConnectionDialog.gatewaySecret')}
      </Typography>
      <TextField
        label={t('servicePage.gatewayConnectionDialog.gatewaySecretFieldLabel')}
        onChange={e => {
          onInputChange(e, 'gatewaySecret');
        }}
        value={gatewaySecret}
        sx={{ mt: 2 }}
        size="small"
        fullWidth
      />
      <DialogFooter
        buttonsData={[
          {
            colorVariant: 'secondary',
            icon: <CloseIcon />,
            label: t('common.cancel'),
            onClick: onClose,
          },
          {
            colorVariant: 'tertiary',
            icon: <CheckmarkIcon />,
            disabled: nextButtonDisabled,
            label: t('common.confirm'),
            onClick: onConfirm,
          },
        ]}
      />
    </DialogContent>
  );
};

export default GatewayConnectionDialogFirstStageContent;
