import { DeleteForever } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackendErrorCode } from '../../../../../config';
import CustomDialogTitle from '../../../../common/dialog/CustomDialogTitle';
import DisconnectSmartboxDialogConfirmationStage from './DisconnectSmartboxDialogConfirmationStage';
import DisconnectSmartboxDialogResultStage from './DisconnectSmartboxDialogResultStage';
import { useDeleteInstallationMutation } from '../../../../../redux/api/iotCloud';

type DisconnectSmartboxDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  installationId: string | number;
};

const DisconnectSmartboxDialog = ({
  isOpen,
  onClose,
  installationId,
}: DisconnectSmartboxDialogProps) => {
  const { t } = useTranslation('translation');

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [resultText, setResultText] = useState<string | null>(null);

  const [deleteInstallation] = useDeleteInstallationMutation();

  const handleDisconnect = () => {
    setIsConfirmed(true);
    deleteInstallation(installationId)
      .unwrap()
      .then(() => {
        setResultText(
          t('servicePage.disconnectSmartboxDialog.smartboxDisconnected'),
        );
      })
      .catch(error => {
        if (
          error?.data?.error?.errorCode === BackendErrorCode.InstallationInUse
        ) {
          setResultText(
            t(
              'servicePage.disconnectSmartboxDialog.installationAlreadyInUseError',
            ),
          );
          return;
        }
        if (error?.status === 403) {
          setResultText(t('common.forbiddenErrorMessage'));
          return;
        }
        setResultText(t('servicePage.disconnectSmartboxDialog.defaultError'));
      })
      .finally();
  };

  const resetDialog = () => {
    setIsConfirmed(false);
    setResultText(null);
  };

  useEffect(() => {
    if (isOpen === false) {
      const timeout = setTimeout(resetDialog, 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      sx={{ maxWidth: '670px', mx: 'auto' }}
    >
      <CustomDialogTitle
        icon={<DeleteForever fill="black" />}
        onClose={onClose}
      >
        {t('servicePage.disconnectSmartboxDialog.title')}
      </CustomDialogTitle>
      {isConfirmed ? (
        <DisconnectSmartboxDialogResultStage
          onClose={onClose}
          resultText={resultText}
        />
      ) : (
        <DisconnectSmartboxDialogConfirmationStage
          onClose={onClose}
          onConfirm={handleDisconnect}
        />
      )}
    </Dialog>
  );
};

export default DisconnectSmartboxDialog;
