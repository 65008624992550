import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmptyTableBody = ({
  numberOfColumns,
  children,
}: {
  numberOfColumns: number;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation('translation');

  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns} sx={{ textAlign: 'center' }}>
        {children !== undefined && typeof children !== 'string' ? (
          children
        ) : (
          <Typography color="secondary.main" variant="h6">
            {children ? children : t('common.noDataTableRow')}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EmptyTableBody;
