import { createApi } from '@reduxjs/toolkit/query/react';

import { objectToQueryString } from '../../../lib/helpers';
import { iotCloudBaseQueryWithReauth } from '../baseQueries';
import {
  GetBatteryChargeHistoryArgs,
  GetBatteryChargeHistoryResult,
  GetBatteryChargeLastResult,
  GetHemsSettingsAuditArgs,
  GetHemsSettingsAuditResult,
  GetInstallationDeletionsResult,
  GetInstallationDeletionStatusResult,
  GetInstallationPowerNowResult,
  GetInstallationsArgs,
  GetInstallationsResult,
  GetInstallationStatusResult,
  GetIotCloudBatteryChargeMigrationStatusResult,
  getIoTCloudKpisResult,
  getIotCloudMeResult,
  GetSearchArgs,
  GetSearchResult,
  HemsSettings,
  Installation,
  IoTCloudMgmtSearchProject,
  IotCloudProjectInfo,
  PatchHemsSettingsArgs,
} from './types';
import { HyperTableChunk, HyperTableWithSize } from './typeCopy';
import qs from 'query-string';

const iotCloudApi = createApi({
  baseQuery: iotCloudBaseQueryWithReauth,
  reducerPath: 'iotCloudApi',
  tagTypes: [
    'batteryChargeMigrationStatus',
    'hemsSettings',
    'projectInfo',
    'installation',
    'installationPage',
    'installationServiceData',
    'installationStatus',
    'installationDeletion',
    'installationDeletionStatus',
  ],
  endpoints: b => ({
    getIotCloudVersion: b.query<string, void>({
      query: () => ({
        url: 'api/v1/version',
        responseHandler: 'text',
      }),
    }),
    getMe: b.query<getIotCloudMeResult, void>({
      query: () => 'api/v1/auth/me',
    }),
    getHyperTables: b.query<HyperTableWithSize[], void>({
      query: () => 'api/v1/utility/hyperTable',
    }),
    getHyperTableChunks: b.query<HyperTableChunk[], string>({
      query: hypertableName =>
        `api/v1/utility/hyperTable/${hypertableName}/chunks`,
    }),
    getBatteryChargeMigrationStatus: b.query<
      GetIotCloudBatteryChargeMigrationStatusResult,
      void
    >({
      providesTags: ['batteryChargeMigrationStatus'],
      query: () => 'api/v1/utility/batteryChargeMigration/status',
    }),
    getIoTCloudKpis: b.query<getIoTCloudKpisResult, void>({
      query: () => 'api/v1/private/kpi',
    }),
    getIotCloudSearch: b.query<GetSearchResult, GetSearchArgs>({
      query: args => `api/v1/private/search${objectToQueryString(args)}`,
    }),
    getIoTCloudProjectInfo: b.query<IotCloudProjectInfo, number | string>({
      providesTags: ['projectInfo'],
      query: projectId => `api/v1/private/project/${projectId}`,
    }),
    getIoTCloudMgmtSearchProject: b.query<
      IoTCloudMgmtSearchProject,
      number | string
    >({
      query: projectId => `api/v1/private/mgmtSearchProject/${projectId}`,
    }),
    getInstallation: b.query<Installation, number | string>({
      providesTags: ['installation'],
      query: installationIdOrUuid =>
        `api/v1/private/installation/${installationIdOrUuid}`,
    }),
    getInstallationStatus: b.query<
      GetInstallationStatusResult,
      string | number
    >({
      providesTags: ['installationStatus'],
      query: installationIdOrUuid =>
        `api/v1/private/installation/${installationIdOrUuid}/status`,
    }),
    getInstallations: b.query<GetInstallationsResult, GetInstallationsArgs>({
      providesTags: ['installationPage'],
      query: args =>
        `api/v1/private/installation${objectToQueryString({
          ...args,
          withTotal: true,
        })}`,
    }),
    getInstallationDeletions: b.query<GetInstallationDeletionsResult, void>({
      providesTags: ['installationDeletion'],
      query: () => 'api/v1/private/installationDeletion',
    }),
    getInstallationServiceData: b.query<void, string | number>({
      providesTags: ['installationServiceData'],
      query: installationId =>
        `api/v1/private/installation/${installationId}/serviceData`,
    }),
    deleteInstallation: b.mutation<void, string | number>({
      invalidatesTags: [
        'batteryChargeMigrationStatus',
        'installationPage',
        'installationServiceData',
        'installationStatus',
        'installationDeletion',
        'installationDeletionStatus',
      ],
      query: installationId => ({
        method: 'DELETE',
        url: `api/v1/private/installation/${installationId}`,
      }),
    }),
    getInstallationDeletionStatus: b.query<
      GetInstallationDeletionStatusResult,
      string | number
    >({
      providesTags: ['installationDeletionStatus'],
      query: installationId =>
        `api/v1/private/installation/${installationId}/deletionStatus`,
    }),
    getInstallationPowerNow: b.query<
      GetInstallationPowerNowResult,
      number | string
    >({
      query: installationIdOrUuid =>
        `api/v1/installation/${installationIdOrUuid}/now/all/power`,
    }),
    getInstallationHemsSettings: b.query<HemsSettings, string | number>({
      query: installationId =>
        `api/v1/private/installation/${installationId}/hemsSetting`,
      providesTags: ['hemsSettings'],
    }),
    patchInstallationHemsSettings: b.mutation<
      HemsSettings,
      PatchHemsSettingsArgs
    >({
      query: ({ installationId, body }) => ({
        url: `api/v1/private/installation/${installationId}/hemsSetting`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['hemsSettings'],
    }),
    getInstallationHemsSettingsAudit: b.query<
      GetHemsSettingsAuditResult,
      GetHemsSettingsAuditArgs
    >({
      query: params =>
        `api/v1/private/hemsSetting/audit${objectToQueryString({
          ...params,
          withTotal: true,
          sort: 'changeAt',
          order: 'desc',
        })}`,
    }),
    getBatteryChargeLast: b.query<GetBatteryChargeLastResult, string | number>({
      query: installationId =>
        `api/v1/private/installation/${installationId}/batteryCharge/last`,
    }),
    getBatteryChargeHistory: b.query<
      GetBatteryChargeHistoryResult,
      GetBatteryChargeHistoryArgs
    >({
      query: ({ installationId, ...queryParams }) =>
        `api/v1/private/installation/${installationId}/batteryCharge/explain?${qs.stringify(
          queryParams,
        )}`,
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetHyperTablesQuery,
  useGetHyperTableChunksQuery,
  useGetBatteryChargeMigrationStatusQuery,
  useGetIotCloudVersionQuery,
  useGetIoTCloudKpisQuery,
  useGetIotCloudSearchQuery,
  useGetIoTCloudProjectInfoQuery,
  useGetIoTCloudMgmtSearchProjectQuery,
  useGetInstallationQuery,
  useGetInstallationStatusQuery,
  useGetInstallationsQuery,
  useGetInstallationDeletionsQuery,
  useDeleteInstallationMutation,
  useGetInstallationDeletionStatusQuery,
  useGetInstallationPowerNowQuery,
  useGetInstallationHemsSettingsQuery,
  usePatchInstallationHemsSettingsMutation,
  useGetInstallationHemsSettingsAuditQuery,
  useGetBatteryChargeLastQuery,
  useGetBatteryChargeHistoryQuery,
} = iotCloudApi;

export default iotCloudApi;
