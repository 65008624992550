import { DialogContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckmarkIcon } from '../../../../../assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ex.svg';
import DialogFooter from '../../../../common/dialog/DialogFooter';

interface GatewayConnectionDialogSecondStageContentProps {
  projectId: string | number;
  customerNumber: string | null;
  serialNumber: string;
  gatewaySecret: string;
  onClose: () => void;
  onConfirm: () => void;
}

const GatewayConnectionDialogSecondStageContent = ({
  projectId,
  customerNumber,
  serialNumber,
  gatewaySecret,
  onClose,
  onConfirm,
}: GatewayConnectionDialogSecondStageContentProps) => {
  const { t } = useTranslation('translation');

  return (
    <DialogContent>
      <Typography>
        {t('servicePage.gatewayConnectionDialog.confirmationText')}
      </Typography>
      <Grid sx={{ mt: 2 }} rowSpacing={1} container>
        {customerNumber && (
          <Grid xs={12} item>
            <Typography sx={{ fontWeight: 700 }}>
              {t('servicePage.gatewayConnectionDialog.customerNumber')}
            </Typography>
            <Typography color="secondary.main" sx={{ mt: 1 }}>
              {customerNumber}
            </Typography>
          </Grid>
        )}
        <Grid xs={12} item>
          <Typography sx={{ fontWeight: 700 }}>
            {t('servicePage.gatewayConnectionDialog.projectId')}
          </Typography>
          <Typography color="secondary.main" sx={{ mt: 1 }}>
            {projectId}
          </Typography>
        </Grid>
        <Grid xs={6} item>
          <Typography sx={{ fontWeight: 700 }}>
            {t('servicePage.gatewayConnectionDialog.ampereSerialNumber')}
          </Typography>
          <Typography color="secondary.main" sx={{ mt: 1 }}>
            {serialNumber}
          </Typography>
        </Grid>
        <Grid xs={6} item>
          <Typography sx={{ fontWeight: 700 }}>
            {t('servicePage.gatewayConnectionDialog.gatewaySecret')}
          </Typography>
          <Typography color="secondary.main" sx={{ mt: 1 }}>
            {gatewaySecret}
          </Typography>
        </Grid>
      </Grid>
      <DialogFooter
        buttonsData={[
          {
            colorVariant: 'secondary',
            icon: <CloseIcon />,
            label: t('common.cancel'),
            onClick: onClose,
          },
          {
            colorVariant: 'tertiary',
            icon: <CheckmarkIcon />,
            label: t('common.confirm'),
            onClick: onConfirm,
          },
        ]}
      />
    </DialogContent>
  );
};

export default GatewayConnectionDialogSecondStageContent;
