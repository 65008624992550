import ApartmentIcon from '@mui/icons-material/Apartment';
import ListIcon from '@mui/icons-material/List';
import { Container, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import React, { useMemo } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import PrimarySearchAppBar from './PrimarySearchAppBar';
import { Link } from 'react-router-dom';
import { hasPermission, IoTCloudPermissionEnum } from '../../lib/helpers';
import { useGetMeQuery } from '../../redux/api/iotCloud';

const getDrawerItems = (t: TFunction) => [
  {
    name: 'Dashboard',
    path: '/servicePortal',
    Icon: ListIcon,
  },
  {
    name: t('servicePortal.search.title'),
    path: '/servicePortal/search',
    Icon: SearchIcon,
  },
  {
    name: t('servicePortal.installationPage.title'),
    path: '/servicePortal/installation',
    Icon: ApartmentIcon,
  },
  {
    name: 'HyperTables',
    path: '/servicePortal/admin/hyperTable',
    Icon: ListIcon,
    permissions: [IoTCloudPermissionEnum.Admin],
  },
];

const DrawerList = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('translation');
  const drawerItems = useMemo(() => getDrawerItems(t), [t]);
  const { data: getMeData } = useGetMeQuery();

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {drawerItems.map(({ name, path, Icon, permissions }) => {
          if (
            permissions &&
            permissions.some(p => !hasPermission(getMeData, p))
          ) {
            return null;
          }

          return (
            <ListItem key={name} disablePadding>
              <ListItemButton component={Link} to={path} onClick={onClick}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const ServicePortalLayout = ({ children }: { children: React.ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const onToggleDrawerFactory = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  return (
    <Container disableGutters={true} maxWidth={false}>
      <PrimarySearchAppBar onToggleDrawer={onToggleDrawerFactory(true)} />
      <Drawer open={drawerOpen} onClose={onToggleDrawerFactory(false)}>
        <DrawerList onClick={onToggleDrawerFactory(false)} />
      </Drawer>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Container>
  );
};

export default ServicePortalLayout;
