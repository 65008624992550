import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { differenceInSeconds, formatDistance } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { formatTimestamp } from '../../../../lib/formatting';
import {
  BatteryChargeRes,
  GetBatteryChargeLastResult,
} from '../../../../redux/api/iotCloud/types';
import { useEffect, useState } from 'react';
import { useDateLocale } from '../../../../context/TranslationContext/useDateLocale';
import { usePrevious } from '../../../../lib/hooks';
import CustomTableCell, {
  CustomTableCellProps,
} from '../../../common/CustomTableCell';

const EmptyTableBody = ({ numberOfColumns }: { numberOfColumns: number }) => {
  const { t } = useTranslation('translation');

  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns} sx={{ textAlign: 'center' }}>
        <Typography color="secondary.main" variant="h6">
          {t('servicePage.historyTable.noData')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

type TableHeaderCellProps = {
  title: string;
  subtitle?: string | null;
};

const BatteryChargeTableHeaderCell = ({
  title,
  subtitle,
}: TableHeaderCellProps) => {
  return (
    <TableCell sx={{ verticalAlign: 'top' }}>
      <Typography
        color="secondary.main"
        variant="h6"
        sx={{ textAlign: 'left', whiteSpace: 'pre-line' }}
      >
        {title}
      </Typography>
      {!!subtitle && (
        <Typography
          color="secondary.main"
          sx={{ textAlign: 'left', whiteSpace: 'pre-line' }}
        >
          {subtitle}
        </Typography>
      )}
    </TableCell>
  );
};

const TimestampCell = ({
  timestamp,
  ...rest
}: {
  timestamp: string | null | undefined;
} & CustomTableCellProps) => {
  const { i18n } = useTranslation('translation');
  const dateLocale = useDateLocale();
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    setNow(new Date());
    const nowInterval = setInterval(
      () => {
        setNow(new Date());
      },
      timestamp ? 1000 : 60000,
    );
    return () => clearInterval(nowInterval);
  }, [timestamp]);
  if (!timestamp) {
    return <CustomTableCell {...rest}>-</CustomTableCell>;
  }
  const seconds = differenceInSeconds(now, new Date(timestamp));
  if (seconds < 120) {
    return (
      <CustomTableCell {...rest}>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Skeleton
            height={10}
            width={10}
            animation="pulse"
            variant="circular"
            sx={{ mr: 1, backgroundColor: 'green' }}
          />
          {!i18n.language.startsWith('en') ? 'vor ' : ''}
          {Math.round((now.getTime() - new Date(timestamp).getTime()) / 1000)}
          {i18n.language.startsWith('en') ? 's ago' : 's'} @{' '}
          {formatTimestamp(timestamp)}
        </Stack>
      </CustomTableCell>
    );
  }
  // display human readable time for more than 120s
  return (
    <CustomTableCell {...rest}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Skeleton
          height={10}
          width={10}
          animation="pulse"
          variant="circular"
          sx={{ mr: 1, backgroundColor: 'gray' }}
        />
        {`${formatDistance(new Date(timestamp), new Date(), {
          locale: dateLocale,
          addSuffix: true,
          includeSeconds: true,
        })} @ ${formatTimestamp(timestamp)}`}
      </Stack>
    </CustomTableCell>
  );
};

const BatteryChargeTableRow = ({ row }: { row: BatteryChargeRes }) => {
  const {
    timestamp,
    batteryId,
    chargingProfile,
    chargingMode,
    relativeChargePower,
    soc,
  } = row;
  const prev = usePrevious(row);

  return (
    <TableRow>
      <TimestampCell timestamp={timestamp} />
      <CustomTableCell>{batteryId}</CustomTableCell>
      <CustomTableCell
        highlight={
          prev &&
          (prev.chargingMode !== chargingMode ||
            prev?.relativeChargePower !== relativeChargePower)
        }
      >
        {`${chargingMode}${
          relativeChargePower ? `: ${relativeChargePower}%` : ''
        }`}
      </CustomTableCell>
      <CustomTableCell
        highlight={prev && prev.soc !== soc}
      >{`${soc}%`}</CustomTableCell>
      <CustomTableCell
        highlight={prev && prev.chargingProfile !== chargingProfile}
      >{`${chargingProfile}`}</CustomTableCell>
    </TableRow>
  );
};

type BatteryChargeTableProps = {
  data?: GetBatteryChargeLastResult;
};

const BatteryChargeTable = ({ data }: BatteryChargeTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.priceSignalCard.lastBatteryCharge',
  });

  const noData = !data || isEmpty(data);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <BatteryChargeTableHeaderCell title={t('timestamp')} />
          <BatteryChargeTableHeaderCell
            title={t('batterySerialNumber.title')}
            subtitle={t('batterySerialNumber.subtitle')}
          />
          <BatteryChargeTableHeaderCell
            title={t('command.title')}
            subtitle={t('command.subtitle')}
          />
          <BatteryChargeTableHeaderCell title="SoC" />
          <BatteryChargeTableHeaderCell title="Profil" />
        </TableRow>
      </TableHead>
      <TableBody>
        {noData && <EmptyTableBody numberOfColumns={6} />}
        {!noData &&
          data.map(row => (
            <BatteryChargeTableRow key={`${row.batteryId}`} row={row} />
          ))}
      </TableBody>
    </Table>
  );
};

export default BatteryChargeTable;
