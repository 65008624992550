import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { HyperTableChunk } from '../../../redux/api/iotCloud/typeCopy';
import { formatBytes, formatTimestamp } from '../../../lib/formatting';

type CellProps = {
  data: string | number | null | undefined;
  linkUrl?: string | null;
  header?: boolean;
};

const Cell = ({ data, linkUrl, header }: CellProps) => {
  const dataComponent = data ? (
    linkUrl ? (
      <Link
        href={linkUrl}
        onClick={ev => {
          ev.stopPropagation();
        }}
        underline="none"
        target="_blank"
      >
        {data}
      </Link>
    ) : (
      data
    )
  ) : (
    '-'
  );

  return (
    <TableCell>
      {header ? (
        <Typography variant="h6">{dataComponent}</Typography>
      ) : (
        dataComponent
      )}
    </TableCell>
  );
};

type HyperTableChunkTableProps = {
  chunks?: HyperTableChunk[];
};

const HyperTableChunkTable = ({ chunks }: HyperTableChunkTableProps) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Cell header data="Chunk name" />
            <Cell header data="Range start" />
            <Cell header data="Range end" />
            <Cell header data="Size" />
          </TableRow>
        </TableHead>
        <TableBody>
          {chunks &&
            chunks.map(
              ({
                hypertable_name,
                chunk_name,
                range_start,
                range_end,
                total_bytes,
              }) => {
                return (
                  <TableRow key={`${hypertable_name}-${chunk_name}`}>
                    <Cell data={chunk_name} />
                    <Cell data={formatTimestamp(range_start)} />
                    <Cell data={formatTimestamp(range_end)} />
                    <Cell data={formatBytes(total_bytes)} />
                  </TableRow>
                );
              },
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HyperTableChunkTable;
