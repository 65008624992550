import { Box, LinearProgress, Typography } from '@mui/material';
import { useGetHyperTablesQuery } from '../../../redux/api/iotCloud';
import ServicePortalLayout from '../ServicePortalLayout';
import HyperTableTable from './HyperTableTable';

const HyperTablePage = () => {
  const { data, isFetching } = useGetHyperTablesQuery();

  return (
    <ServicePortalLayout>
      <Box
        sx={{
          my: 1,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      <Typography variant="h3" sx={{ pb: 5 }}>{`HyperTables`}</Typography>
      <HyperTableTable hyperTables={data} />
    </ServicePortalLayout>
  );
};

export default HyperTablePage;
