import {
  BatteryChargeMigrationJobData,
  BatteryChargingProfile,
  HyperTableChunk,
  SimpleJobState,
} from './typeCopy';

export type getIotCloudMeResult = {
  context: {
    clientType: string;
  };
  user: {
    permissions: string[];
  };
};

export type GetIotCloudBatteryChargeMigrationStatusResult = {
  isPaused: boolean;
  setting: {
    setting_name: string;
    migrate_until: string;
    delete_migrated_chunks_before: string;
    installation_batch_size: number;
  };
  chunksToMigrate: HyperTableChunk[];
  migratedToTimestamp: string | null;
  battery_charge_tableSize: number | null;
  battery_charge_v2_tableSize: number | null;
  initial_battery_charge_tableSize: number | null;
  initial_battery_charge_v2_tableSize: number | null;
  jobs: {
    id?: string;
    name: string;
    state: SimpleJobState;
    data: BatteryChargeMigrationJobData;
    failedReason?: string;
  }[];
};

export type GetInstallationsArgs = {
  installationId?: number;
  projectId?: number;
  partialKgGatewaySerialNumber?: string;
  limit?: number;
  offset?: number;
  withTotal?: boolean;
  hasCoordinates?: 'true' | 'false' | string;
};

export type Installation = {
  id: string;
  uuid: string;
  ownerId: string | null;
  projectId: number | null;
  lat: number | null;
  lng: number | null;
  kgGatewaySerialNumber: string | null;
  kgGatewayGuid: string | null;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string | null;
  deletedBy: string | null;
  gatewayType: string;
  azureDeviceId: number | null;
  commissioningDate: string | null;
  wPeak: number | null;
};

export type InstallationDeletionStatus = {
  state: SimpleJobState;
  installationId: number;
  projectId: number | null;
  kgGatewaySerialNumber: string | null;
  queuedAt: string | null;
  startedAt: string | null;
  finishedAt: string | null;
};

export type GetInstallationsResult = {
  data: Installation[];
  meta: {
    total: number;
    offset: number;
    limit: number;
    count: number;
  };
};

export type GetInstallationDeletionsResult = InstallationDeletionStatus[];
export type GetInstallationDeletionStatusResult = InstallationDeletionStatus;

export type ElectricityPrice = {
  timestamp: string;
  value: number;
};

export type getIoTCloudKpisResult = {
  installationCount: number;
  installationWithOwnerCount: number;
  currentElectricityPrice: ElectricityPrice | null;
};

export enum HemsBatteryChargingMode {
  Gentle = 'gentle',
  Fast = 'fast',
}

export enum HemsBatteryChargingStrategy {
  Manual = 'manual',
  Dynamic = 'dynamic',
}

export type HemsSettings = {
  id: number;
  batteryChargingEnabled: boolean;
  batteryChargingThreshold: number | null;
  batteryChargingMode: HemsBatteryChargingMode | null;
  batteryChargingPriceLimit: number;
  batteryChargingStrategy: HemsBatteryChargingStrategy;
  evChargingEnabled: boolean;
  evChargingSnoozeUntilTimestamp: Date | null;
  emergencyPowerEnabled: boolean;
  emergencyPowerLimit: number | null;
};

export type PatchHemsSettingsArgs = {
  installationId: string | number;
  body: Partial<HemsSettings>;
};

export type HemsSettingsAudit = HemsSettings & {
  changeAt: string;
  changeBy: string;
  changeType: string;
  changeRef: string;
};

export type GetHemsSettingsAuditResult = {
  data: HemsSettingsAudit[];
  meta: {
    total: number;
    offset: number;
    limit: number;
    count: number;
  };
};

export type GetHemsSettingsAuditArgs = {
  installationId: string | number;
  changedAtAfter?: string;
  changedAtBefore?: string;
  offset?: number;
  limit?: number;
};

export type GetInstallationPowerNowResult = {
  batteryPower: number | null;
  batterySoc: number | null;
  gridPower: number | null;
  heatPumpPower: number | null;
  heatingRodPower: number | null;
  housePower: number | null;
  pvPower: number | null;
  wallboxPower: number | null;
};

export type GetBatteryChargeHistoryArgs = {
  installationId: string | number;
  from: string;
  to: string;
  sort: string | string[];
  order: 'asc' | 'desc' | ('asc' | 'desc')[];
  limit: number;
  offset?: number;
};

type ChargingMode = 'gentle' | 'fast';

export type BatteryChargeRes = {
  timestamp: string;
  batteryId: string;
  soc: number;
  chargingProfile: BatteryChargingProfile | null;
  chargingMode: ChargingMode | null;
  relativeChargePower: number | null;
};

export type GetBatteryChargeLastResult = BatteryChargeRes[] | null;

export type GetBatteryChargeHistoryItem = BatteryChargeRes & {
  electricityPrice: number;
  hemsSettingBatteryChargingEnabled: boolean;
  hemsSettingBatteryChargingStrategy: HemsBatteryChargingStrategy | null;
  hemsSettingBatteryChargingThreshold: number | null;
  hemsSettingBatteryChargingMode: ChargingMode | null;
  hemsSettingBatteryChargingPriceLimit: number | null;
  hemsSettingChangeAt: string | null;
  prevOfflineSince: string;
  prevOfflineFor: string;
};

export type GetBatteryChargeHistoryResult = {
  data: GetBatteryChargeHistoryItem[];
  meta: {
    total: number;
    offset: number;
    limit: number;
    count: number;
  };
};

export enum InstallationOnlineStatus {
  Unknown = 'UNKNOWN',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export enum InstallationConnectionStatus {
  NotConnected = 'NOT_CONNECTED',
  ProjectConnected = 'PROJECT_CONNECTED',
  Connected = 'CONNECTED',
}

export type GetInstallationStatusResult = {
  lastRequest: string | null;
  onlineStatus: InstallationOnlineStatus;
  connectionStatus: InstallationConnectionStatus;
};

export type IoTCloudMgmtSearchProject = {
  id: number;
  customerNumber: string | null;
  title: string;
  lmUrl: string;
  customerName: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerAddress: string;
  customerZip: string;
  customerCity: string;
};

export type UserAccount = {
  id: number;
  uuid: string;
  auth0UserId: string | null;
  kgUserId: string | null;
  kgUsername: string | null;
  kgEmail: string | null;
  kgUserRoleVersion: string | null;
  deletionRequestedAt: string | null;
  mockUserId: number | null;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: number;
};

export type SearchResultItem = {
  id: string;
  projectId: number | null;
  installation: Installation | null;
  userAccount: UserAccount | null;
  project: IoTCloudMgmtSearchProject | null;
};

export type GetSearchResult = {
  specific: SearchResultItem;
  results: SearchResultItem[] | null;
  canLoadMore: boolean;
};

export type GetSearchArgs = {
  phrase: string;
  loadMore?: number | string;
  lazyResults?: boolean;
  skipAfterSpecific?: boolean | 'true' | 'false' | string;
  onlySpecific?: boolean | 'true' | 'false' | string;
};

export type IotCloudProjectInfo = {
  projectId: number;
  installation: Installation | null;
  userAccount: UserAccount | null;
  installerCenterLink: string | null;
  setupLink: string | null;
  gatewaySecret: string | null;
};
