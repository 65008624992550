import { MouseEvent } from 'react';
import {
  CircularProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetInstallationDeletionsQuery } from '../../../redux/api/iotCloud';
import LoadingCard from '../InstallationDetailsPage/LoadingCard';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, orderBy } from 'lodash';
import { formatTimestamp } from '../../../lib/formatting';
import QueueIcon from '@mui/icons-material/Queue';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { InstallationDeletionStatus } from '../../../redux/api/iotCloud/types';
import { SimpleJobState } from '../../../redux/api/iotCloud/typeCopy';

const InstallationDeletionStatusIcon = ({
  status,
  failedAndNotRetried,
}: {
  status: (InstallationDeletionStatus & { key: string }) | null;
  failedAndNotRetried: (InstallationDeletionStatus & { key: string })[];
}) => {
  if (!status || !status.state) {
    return null;
  }
  const { state, key } = status;
  if (state === SimpleJobState.queued) {
    return <QueueIcon color="info" />;
  }
  if (state === SimpleJobState.processing) {
    return <CircularProgress size={24} />;
  }
  if (state === SimpleJobState.completed) {
    return <DoneIcon color="success" />;
  }
  if (state === SimpleJobState.failed) {
    if (failedAndNotRetried.find(inner => inner.key === key)) {
      return <ErrorIcon color="error" />;
    }
    return <WarningIcon color="warning" />;
  }
  return <Typography>{state}</Typography>;
};

type InstallationDeletionCardFilter = (
  status: InstallationDeletionStatus,
) => boolean;

export const getFailedAndNotRetriedInstallationDeletion = <
  T extends InstallationDeletionStatus,
>(
  deletions?: T[],
) =>
  deletions?.filter(
    status =>
      status.state === SimpleJobState.failed &&
      !deletions.find(
        inner =>
          status.installationId === inner.installationId &&
          inner?.queuedAt &&
          status?.queuedAt &&
          new Date(inner.queuedAt) > new Date(status.queuedAt),
      ),
  ) ?? [];

const InstallationDeletionTable = ({
  data,
  filter,
  highlight,
}: {
  data: InstallationDeletionStatus[];
  filter?: InstallationDeletionCardFilter;
  highlight?: InstallationDeletionCardFilter;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.installationDeletion',
  });
  const navigate = useNavigate();

  const handleRowPress = (event: MouseEvent<HTMLTableRowElement>) => {
    const { installationId, projectId } = event.currentTarget.dataset;
    if (isNil(installationId) || isNil(projectId)) {
      return;
    }
    navigate(`/servicePortal/installation/${installationId}`);
  };

  const noData = !data || isEmpty(data);
  if (noData) {
    return <Typography>{t('none')}</Typography>;
  }
  const sorted = orderBy(data, ['deletionStatus?.queuedAt'], ['asc']);
  const filtered = filter ? sorted.filter(filter) : sorted;
  let queuePosition = 0;
  const toRender = filtered.map(status => ({
    ...status,
    key: `${status.installationId}-${status.queuedAt}`,
    queuePosition:
      status.state === SimpleJobState.queued ? ++queuePosition : null,
  }));
  const failedAndNotRetried =
    getFailedAndNotRetriedInstallationDeletion(toRender);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              #
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              Status
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              {t('table.queuedAt')}
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              {t('table.startedAt')}
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              {t('table.finishedAt')}
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              ID
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              Project ID
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: 'top' }}>
            <Typography color="secondary.main" variant="h6">
              KG Serial Number
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {toRender.map(status => {
          const {
            key,
            queuePosition,
            installationId,
            projectId,
            queuedAt,
            startedAt,
            finishedAt,
            kgGatewaySerialNumber,
          } = status;
          return (
            <TableRow
              key={key}
              hover={!isNil(installationId) && !isNil(projectId)}
              data-project-id={projectId}
              data-installation-id={installationId}
              onClick={handleRowPress}
              selected={highlight && highlight(status)}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell>
                <Typography>{queuePosition}</Typography>
              </TableCell>
              <TableCell>
                <InstallationDeletionStatusIcon
                  status={status}
                  failedAndNotRetried={failedAndNotRetried}
                />
              </TableCell>
              <TableCell>
                <Typography>{formatTimestamp(queuedAt)}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{formatTimestamp(startedAt)}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{formatTimestamp(finishedAt)}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{installationId}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{projectId}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{kgGatewaySerialNumber}</Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const InstallationDeletionCard = ({
  highlight,
  filter,
  title,
}: {
  highlight?: InstallationDeletionCardFilter;
  filter?: InstallationDeletionCardFilter;
  title?: string | null;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.installationDeletion',
  });
  const {
    data: installationDeletions,
    isFetching,
    isSuccess,
  } = useGetInstallationDeletionsQuery(undefined, {
    pollingInterval: 30000,
    skipPollingIfUnfocused: true,
  });
  return (
    <LoadingCard
      isLoading={!installationDeletions && isFetching}
      isLoadingWithContent={isFetching}
      title={title ?? t('title')}
    >
      {isSuccess && (
        <InstallationDeletionTable
          data={installationDeletions || []}
          filter={filter}
          highlight={highlight}
        />
      )}
      {!isSuccess && <Skeleton variant="rectangular" height={100} />}
    </LoadingCard>
  );
};

export default InstallationDeletionCard;
